// PDFContentAtRetirement.js
import React from "react";
import { Grid, Paper, Typography, Box, Stack, Chip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { BarChart } from "@mui/x-charts/BarChart";
import TableHead from "@mui/material/TableHead";
import { blueGrey, green } from "@mui/material/colors";
import PDFCurrentRetMarketSignal from './PDFCurrentRetMarketSignal';
import Disclaimer from './Disclaimer';

const captionTextSize = '0.4rem'
const helpTextSize = '0.5rem'
const small =  '0.65rem' 
const tableHeader = '0.7rem'
const footerText = '0.6rem'

const PDFContentAtRetirement = ({
  pdfData, marketSignal
}) => {
    const data = pdfData ? pdfData : {};
    const {longTermBarChart,
        mediumTermBarChart,
        shortTermBarChart,
        selectedExposureType,
        atRetirementTableCurrentHighGrowth,
        atRetirementTableCurrentGrowth,
        atRetirementTableCurrentBalancedGrowth,
        atRetirementTableCurrentBalanced,
        atRetirementTableCurrentConservative,
        atRetirementTableCurrentMediumTerm,
        atRetirementTableCurrentShortTerm,
        atRetirementTableSwitchHighGrowth,
        atRetirementTableSwitchGrowth,
        atRetirementTableSwitchBalancedGrowth,
        atRetirementTableSwitchBalanced,
        atRetirementTableSwitchConservative,
        atRetirementTableSwitchMediumTermData,
        atRetirementTableSwitchShortTermData, 
        inputs,
    } = data
    const inputData = inputs ? inputs : {}

    const formattedDate = (date) => {
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      }).format(date);
    };

  return (
    <>
      <Grid container sx={{ p: 1, height: "800px"}}>
        <Grid item sm={12} sx={{ backgroundColor: "#FFF" }}>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" style={{ paddingTop: "10px", marginLeft: "5px"}}>Retirement Calculator</Typography>
          <img src="/cornerstone-logo.png" alt="Cornerstone" width="254px" height="46px" />
        </Box>
        <Typography variant="subtitle1" style={{ paddingTop: "10px", marginLeft: "5px", marginTop: "5px" }}>At retirement report</Typography>
        </Grid> 
    
        <Grid item sm={12} sx={{ p: 1 }}>
            <Typography variant="body2" sx={{ fontSize: small }}><strong>Client name:</strong> {inputData.clientName} </Typography>
            <Typography variant="body2" sx={{ fontSize: small }}><strong>Adviser name:</strong> {inputData.adviserName} </Typography>
            <Typography variant="body2" sx={{ fontSize: small }}><strong>Date:</strong> {formattedDate(new Date())} </Typography>
          </Grid>
       <PDFCurrentRetMarketSignal marketSignal={marketSignal} isAtRetirement />
        <Grid container spacing={1} sx={{p: 1}}>
          <Grid item sm={5.5}>
            <BarChart
              /*slotProps={{ legend: { hidden: true } }}*/
              height={400}
              series={[
                { ...longTermBarChart, stack: "total", color: "#394F87" },
                { ...mediumTermBarChart, stack: "total", color: "#2196F3" },
                { ...shortTermBarChart, stack: "total", color: "#A9A8A8" },
              ]}
              slotProps={{
                legend: {
                  labelStyle: { fontSize: 8},
                },
              }}
              bottomAxis={null}
            />
          </Grid>
          <Grid item sm={6.5}>
            <TableContainer
              sx={{ borderRadius: 1, border: "1px solid #E0E0E0", marginTop: 11}}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      <Typography sx={{ fontWeight: "bold", fontSize: tableHeader }}>Bucket</Typography>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      <Typography  sx={{ fontWeight: "bold", fontSize: tableHeader  }}>Current</Typography>
                      <Typography sx={{fontSize: helpTextSize  }}>Where client is now</Typography>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      <Typography sx={{ fontWeight: "bold", fontSize: tableHeader  }}>Adviser instructions: switch to</Typography>
                      <Typography  sx={{fontSize: helpTextSize  }}>Where client wants to be</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      <Typography sx={{fontWeight:'bold', fontSize: small  }}>Long-term</Typography>
                    </TableCell >
                    {selectedExposureType === "Cornerstone" ? (
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              {atRetirementTableCurrentHighGrowth > 0 && (
                                <TableRow>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                    <Typography  sx={{fontSize: small}}>High Growth</Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ p: 0, m: 0, border: 0 }}
                                    align="right"
                                  >
                                    <Typography  sx={{fontSize: small  }}>
                                      {atRetirementTableCurrentHighGrowth}%
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {atRetirementTableCurrentGrowth > 0 && (
                                <TableRow>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                    <Typography sx={{fontSize: small }}>Growth</Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ p: 0, m: 0, border: 0 }}
                                    align="right"
                                  >
                                    <Typography sx={{fontSize: small  }}>
                                      {atRetirementTableCurrentGrowth}%
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {atRetirementTableCurrentBalancedGrowth > 0 && (
                                <TableRow>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                    <Typography sx={{fontSize: small }}>Balanced Growth</Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ p: 0, m: 0, border: 0 }}
                                    align="right"
                                  >
                                    <Typography sx={{fontSize: small }}>
                                      {atRetirementTableCurrentBalancedGrowth}%
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {atRetirementTableCurrentBalanced > 0 && (
                                <TableRow>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                    <Typography sx={{fontSize: small }}>Balanced</Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ p: 0, m: 0, border: 0 }}
                                    align="right"
                                  >
                                    <Typography sx={{fontSize: small }}>
                                      {atRetirementTableCurrentBalanced}%
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {atRetirementTableCurrentConservative > 0 && (
                                <TableRow>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                    <Typography sx={{fontSize: small }}>Conservative</Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ p: 0, m: 0, border: 0 }}
                                    align="right"
                                  >
                                    <Typography sx={{fontSize: small }}>
                                      {atRetirementTableCurrentConservative}%
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    ) : (
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: small }}>
                                  Other Funds
                                </TableCell>
                                <TableCell
                                  sx={{ p: 0, m: 0, border: 0, fontSize: small }}
                                  align="right"
                                >
                                  {100 -
                                    atRetirementTableCurrentMediumTerm -
                                    atRetirementTableCurrentShortTerm}
                                  %
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>
                    )}

                    <TableCell align="right" sx={{ verticalAlign: 'top' }}>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            {atRetirementTableSwitchHighGrowth > 0 && (
                              <TableRow>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                  <Typography sx={{fontSize: small  }}>High Growth</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ p: 0, m: 0, border: 0 }}
                                  align="right"
                                >
                                  <Typography sx={{fontSize: small  }}>
                                    {atRetirementTableSwitchHighGrowth}%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            {atRetirementTableSwitchGrowth > 0 && (
                              <TableRow>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                  <Typography sx={{fontSize: small  }}>Growth</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ p: 0, m: 0, border: 0 }}
                                  align="right"
                                >
                                  <Typography sx={{fontSize: small  }}>
                                    {atRetirementTableSwitchGrowth}%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            {atRetirementTableSwitchBalancedGrowth > 0 && (
                              <TableRow>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                  <Typography sx={{fontSize: small  }}>Balanced Growth</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ p: 0, m: 0, border: 0 }}
                                  align="right"
                                >
                                  <Typography sx={{fontSize: small  }}>
                                    {atRetirementTableSwitchBalancedGrowth}%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            {atRetirementTableSwitchBalanced > 0 && (
                              <TableRow>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                  <Typography sx={{fontSize: small  }}>Balanced</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ p: 0, m: 0, border: 0 }}
                                  align="right"
                                >
                                  <Typography sx={{fontSize: small  }}>
                                    {atRetirementTableSwitchBalanced}%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            {atRetirementTableSwitchConservative > 0 && (
                              <TableRow>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}>
                                  <Typography sx={{fontSize: small  }}>Conservative</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ p: 0, m: 0, border: 0 }}
                                  align="right"
                                >
                                  <Typography sx={{fontSize: small  }}>
                                    {atRetirementTableSwitchConservative}%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Typography  sx={{fontSize: captionTextSize }}>
                        Investment Growth
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontWeight:'bold', fontSize: small  }}>Medium-term</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography sx={{fontSize: small  }}>{atRetirementTableCurrentMediumTerm}%</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography sx={{fontSize: small  }}>{atRetirementTableSwitchMediumTermData}%</Typography>
                      <Typography sx={{fontSize: captionTextSize }} >
                      ~{((atRetirementTableSwitchMediumTermData/inputs.annualDrawdownRateInput).toFixed(1))} Years Stable Income
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography sx={{fontWeight:'bold', fontSize: small }}>Short-term</Typography></TableCell>
                    <TableCell align="right"><Typography sx={{fontSize: small }}>{atRetirementTableCurrentShortTerm}%</Typography></TableCell>
                    <TableCell align="right"><Typography sx={{fontSize: small }}>{atRetirementTableSwitchShortTermData}%</Typography>
                                            <Typography sx={{fontSize: captionTextSize }}>~3 Years Spending</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>


      </Grid>

      {/* <Grid container sx={{ p: 1 }}>
        <Grid item sm={12} sx={{ backgroundColor: "#FFF" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" style={{ paddingTop: "10px", marginLeft: "5px" }}>Retirement Calculator</Typography>
            <img src="/cornerstone-logo.png" alt="Cornerstone" width="254px" height="46px" />
          </Box>
          <Typography style={{ paddingTop: "10px", marginLeft: "5px", marginTop: "5px" }}>Disclaimer</Typography>
        </Grid>

        <Box sx={{ pl: 1, pr: 1, pt: 0 }}>
          <Grid container>
            <Grid item sm={12}>
              <Disclaimer variant={'caption'} isPdf={true} />
            </Grid>
          </Grid>
        </Box>

      </Grid> */}
    </>
  );
};

export default PDFContentAtRetirement;
