import React from 'react';
import { Paper, Typography, Stack, Chip, Grid } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CONSTANTS } from '../utils/constants';

const CurrentRetirementMarketSignals = ({ marketSignal, isAtRetirement }) => {
    
    return (
        <>
            {marketSignal === "green" && (
                <Paper elevation={0} style={{ padding:18, backgroundColor: green[100], color: green[900], borderColor: green[900], border: '1px solid' }}>
                    <Grid container>
                        <Grid item md={12}>
                            <Stack direction='row' justifyContent='space-between'>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>{CONSTANTS.CURRENT_MARKET_SIGNAL}</Typography>
                                <Chip label="Green" color="primary" variant="outlined" sx={{ borderColor: green[900], color: '#FFF', backgroundColor: green[900] }} />
                            </Stack>
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="h7"><strong>{CONSTANTS.ADVISER_INSTRUCTIONS}</strong>{CONSTANTS.CONFIGURE_PLATFORM_AS_BELOW}</Typography>
                            <Typography component="div">
                                <ul>
                                    <li>
                                        <Typography >{CONSTANTS.SELL_PROFILE}</Typography>
                                    </li>
                                    <li>
                                        <Typography>{isAtRetirement ? CONSTANTS.BUY_PROFILE :
                                         CONSTANTS.PRE_BUY_PROFILE}</Typography>
                                    </li>
                                    <li>
                                        <Typography>{CONSTANTS.DISTRIBUTIONS}</Typography>
                                    </li>
                                    <li>
                                        <Typography>{CONSTANTS.ANNUALLY_REBALANCE}</Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{pl:2}}>
                            <Typography variant="h7" sx={{fontWeight:'bold', lineHeight:2}}>{CONSTANTS.WHAT_IS_GREEN_MARKET_SIGNAL}</Typography>
                            <Typography variant="body1">{isAtRetirement ? CONSTANTS.GREEN_MARKET_SIGNAL_DESC : CONSTANTS.PRE_GREEN_MARKET_SIGNAL_DESC}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )}
            {marketSignal === "red" && (
                <Paper elevation={0} style={{ padding: 16, backgroundColor: red[100], color: red[900], borderColor: red[900], border: '1px solid' }}>
                    <Grid container>
                        <Grid item md={12}>
                            <Stack direction='row' justifyContent='space-between'>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>{CONSTANTS.CURRENT_MARKET_SIGNAL}</Typography>
                                <Chip label="Red" color="primary" variant="outlined" sx={{ borderColor: red[900], color: '#FFF', backgroundColor: red[900] }} />
                            </Stack>
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="h7"><strong>{CONSTANTS.ADVISER_INSTRUCTIONS}</strong> {CONSTANTS.CONFIGURE_PLATFORM_AS_BELOW}</Typography>
                            <Typography component="div">
                                <ul>
                                    <li>
                                        <Typography >{CONSTANTS.SELL_PROFILE}</Typography>
                                    </li>
                                    <li>
                                        <Typography>{isAtRetirement ? CONSTANTS.BUY_PROFILE :
                                         CONSTANTS.PRE_BUY_PROFILE}</Typography>
                                    </li>
                                    <li>
                                        <Typography>{CONSTANTS.DISTRIBUTIONS}</Typography>
                                    </li>
                                    <li>
                                        <Typography>{CONSTANTS.ANNUALLY_REBALANCE_RED}</Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{pl:2}}>
                            <Typography variant="h7" sx={{fontWeight:'bold', lineHeight:2}}>{CONSTANTS.WHAT_IS_RED_MARKET_SIGNAL}</Typography>
                            <Typography variant="body1">{isAtRetirement ? CONSTANTS.RED_MARKET_SIGNAL_DESC: CONSTANTS.PRE_RED_MARKET_SIGNAL_DESC}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    )
}

export default CurrentRetirementMarketSignals;