// AtRetirementPieChart.js
import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';


const AtRetirementPieChart = ({ shortTermData, mediumTermData, longTermData }) => (
    <PieChart
        colors={['#2196F3', '#A9A8A8', '#394F87']}
        series={[
            {
                data: [
                    { id: 0, value: shortTermData },
                    { id: 1, value: mediumTermData },
                    { id: 2, value: longTermData },
                ],
            },
        ]}
        width={325}
        height={250}
        margin={{top: 5,left:0,}}
    />
);

export default AtRetirementPieChart;