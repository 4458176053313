// ProjectedGlidepathTable.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid } from '@mui/material';

import { blueGrey } from '@mui/material/colors';

const InvestmentInstructionsTable = ({ longTermData, mediumTermData, shortTermData, Conservative, Balanced, BalancedGrowth, Growth, HighGrowth, OtherFund,
                actualGrowthExposure, isNewClient, longTermBucketInput, selectedExposureType, AdvisorBuyProfile }) => {
  let buyProfileLongTerm
  let buyProfileMediumTerm
  let buyProfileShortTerm
  let currentYearCaption = isNewClient ? <span>Start client on glide path<sup>†</sup></span> : "Where client is now";
  let initialMinInvestment = 5000;

  let remainderLongTerm = longTermBucketInput-initialMinInvestment*2;
  let currentYearLongTerm = isNewClient ? `$${remainderLongTerm.toLocaleString()}` :  selectedExposureType === 'Cornerstone' ? "" : `${100 - mediumTermData[0] - shortTermData[0]}%`;
  let currentYearMediumTerm = isNewClient ? `$${initialMinInvestment.toLocaleString()}` :  `${mediumTermData[0]}%`;
  let currentYearShortTerm = isNewClient ? `$${initialMinInvestment.toLocaleString()}` :  `${shortTermData[0]}%`;
  let currentGrowthExposure = isNewClient ? "" :  `${actualGrowthExposure[0]}%`;

  buyProfileLongTerm = AdvisorBuyProfile[0];
  buyProfileMediumTerm = AdvisorBuyProfile[1];      //Math.round(mediumTermData[mediumTermData.length - 1] / (mediumTermData[mediumTermData.length - 1] + shortTermData[shortTermData.length - 1]) * 100)
  buyProfileShortTerm =  AdvisorBuyProfile[2];    //100 - buyProfileMediumTerm
  return (<>
    <Grid container>
      <Grid item md={9}>
        <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                <TableCell><Typography sx={{ fontWeight: 'bold' }}>Bucket</Typography></TableCell>
                <TableCell><Typography sx={{ fontWeight: 'bold' }}>Current year</Typography><Typography variant='caption'>{currentYearCaption}</Typography></TableCell>
                <TableCell><Typography sx={{ fontWeight: 'bold' }}>Rebalance in 12 months</Typography><Typography variant='caption'>Where client wants to be</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ verticalAlign: 'top' }}>
                <TableCell sx={{ fontWeight: 'bold', verticalAlign: 'top' }}>Long-term</TableCell>
                {isNewClient ?
                (<TableCell>
                <TableContainer >
                  <Table size='small'>
                    <TableBody>
                        <TableRow >
                          <TableCell sx={{ p: 0, m: 0, border: 0 }}></TableCell>
                          <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{currentYearLongTerm}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>)
                : 
                selectedExposureType === 'Cornerstone' ?
                  (<TableCell>
                    <TableContainer >
                      <Table size='small'>
                        <TableBody>
                          {OtherFund[0] > 0 && (
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>Other Fund</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(OtherFund[0])}%</TableCell>
                            </TableRow>
                          )}
                          {HighGrowth[0] > 0 && (
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>High Growth</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(HighGrowth[0])}%</TableCell>
                            </TableRow>
                          )}
                          {Growth[0] > 0 && (
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>Growth</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(Growth[0])}%</TableCell>
                            </TableRow>
                          )}
                          {BalancedGrowth[0] > 0 && (
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>Balanced Growth</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(BalancedGrowth[0])}%</TableCell>
                            </TableRow>
                          )}
                          {Balanced[0] > 0 && (
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>Balanced</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(Balanced[0])}%</TableCell>
                            </TableRow>
                          )}
                          {Conservative[0] > 0 && (
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>Conservative</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(Conservative[0])}%</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>) 
                  : 
                  (<TableCell>
                    <TableContainer >
                      <Table size='small'>
                        <TableBody>
                            <TableRow >
                              <TableCell sx={{ p: 0, m: 0, border: 0 }}>Other Funds</TableCell>
                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{currentYearLongTerm}</TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>)
                }

                <TableCell>
                  <TableContainer >
                    <Table size='small'>
                      <TableBody>
                        {OtherFund[1] > 0 && (
                          <TableRow >
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>Other Fund</TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(OtherFund[1])}%</TableCell>
                          </TableRow>
                        )}
                        {HighGrowth[1] > 0 && (
                          <TableRow >
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>High Growth</TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(HighGrowth[1])}%</TableCell>
                          </TableRow>
                        )}
                        {Growth[1] > 0 && (
                          <TableRow >
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>Growth</TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(Growth[1])}%</TableCell>
                          </TableRow>
                        )}
                        {BalancedGrowth[1] > 0 && (
                          <TableRow >
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>Balanced Growth</TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(BalancedGrowth[1])}%</TableCell>
                          </TableRow>
                        )}
                        {Balanced[1] > 0 && (
                          <TableRow >
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>Balanced</TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(Balanced[1])}%</TableCell>
                          </TableRow>
                        )}
                        {Conservative[1] > 0 && (
                          <TableRow >
                            <TableCell sx={{ p: 0, m: 0, border: 0 }}>Conservative</TableCell>
                            <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'>{Math.round(Conservative[1])}%</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Medium-term</TableCell>
                <TableCell align='right'>{currentYearMediumTerm}</TableCell>
                <TableCell align='right'>{mediumTermData[1]}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Short-term</TableCell>
                <TableCell align='right'>{currentYearShortTerm}</TableCell>
                <TableCell align='right'>{shortTermData[1]}%</TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: blueGrey[50] }}>
                <TableCell sx={{ fontWeight: 'bold' }} >Growth Exposure</TableCell>
                <TableCell align='right'>{currentGrowthExposure}</TableCell>
                <TableCell align='right'>{actualGrowthExposure[1]}%</TableCell>

              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item md={3}>
        <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0', ml: 2 }}>
          <Table >
            <TableHead>
              <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                <TableCell colSpan={2} ><Typography sx={{ fontWeight: 'bold' }} >Adviser instructions - set buy profile to</Typography><Typography variant='caption'>How to get there</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Long-term</TableCell>
                <TableCell>{buyProfileLongTerm}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Medium-term</TableCell>
                <TableCell>{buyProfileMediumTerm}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Short-term</TableCell>
                <TableCell>{buyProfileShortTerm}%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item md={12} sx={{ mt: 1 }}>
        {isNewClient && <Typography variant='caption'><sup><strong>&dagger;</strong></sup>As client is starting on the glide path, open the short and medium-term managed portfolios. Transfer the minimum investment amount (currently $5,000) to both portfolios from the long-term portfolio and set the buy profile as instructed above.</Typography>}
      </Grid>
      <Grid item md={12} sx={{ mt: 1 }}>
      <Typography variant='caption'><strong>Note:</strong> If annual contributions exceed or are expected to exceed 15% of balance at the start of the year, review destination of contributions with adviser.</Typography>
      </Grid>
    </Grid>
  </>
  );
};

export default InvestmentInstructionsTable;
