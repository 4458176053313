// PDFContent.js
import React from 'react';
import PersonalisedGlidepathAreaChart from './PersonalisedGlidePathAreaChart';
import { Grid, Paper, Typography, Box, Stack, Chip, TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { green, blueGrey } from '@mui/material/colors';
import AtRetirementPieChart from './AtRetirementPieChart';
import PersonalisedGlidePathTable from './PersonalisedGlidePathTable';
import WithinLongTermBucket from './withinLongTermBucketTable';
import PDFCurrentRetMarketSignal from './PDFCurrentRetMarketSignal';
import Disclaimer from './Disclaimer';

const footerText = '0.6rem'

const PDFContent = ({ actualGrowthExposure, longTermData, mediumTermData, shortTermData, timeToRetirement, Conservative, Balanced, BalancedGrowth, Growth, HighGrowth, OtherFund, pdfData, results, isNewClient, marketSignal }) => {
  const data = pdfData ? pdfData : {};
  const totalBalance = data.longTermBucketInput + data.mediumTermBucketInput + data.shortTermBucketInput;
  let currentYearCaption = isNewClient ? <span>Start client on glide path<sup>†</sup></span> : "Where client is now";
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    }).format(date);
  };

  // console.log("PDFContent:", JSON.stringify(data))
  // console.log("PDFContent results:", JSON.stringify(results))
  return (
    <>
      <Grid container sx={{ p: 1, height: "980px", marginBottom: "130px" }}>
        <Grid item sm={12} sx={{ backgroundColor: "#FFF" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" style={{ paddingTop: "10px", marginLeft: "5px" }}>Retirement Calculator</Typography>
            <img src="/cornerstone-logo.png" alt="Cornerstone" width="254px" height="46px" />
          </Box>
          <Typography style={{ paddingTop: "10px", marginLeft: "5px", marginTop: "5px" }}>Pre-retirement report</Typography>
        </Grid>
        <Grid item sm={12} sx={{ marginLeft: "8px" }}>
          <Typography variant="body2" sx={{ fontSize: '0.6rem' }}><strong>Client name:</strong> {data.clientName} </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.6rem' }}><strong>Adviser name:</strong> {data.adviserName} </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.6rem' }}><strong>Date:</strong> {formatDate(new Date())} </Typography>
        </Grid>
        <PDFCurrentRetMarketSignal marketSignal={marketSignal} isAtRetirement={false} />
        <Typography style={{ paddingTop: "10px", marginLeft: "5px", marginTop: "5px" }}>Client inputs</Typography>
        <Grid item lg={12} sx={{ display: 'grid', gridTemplateColumns: `repeat(3, 1fr)`, gap: 0.1 }}>
          <Paper elevation={0} sx={{ p: 1, m: 1, backgroundColor: "#F2F2F2", }}>
            <Typography variant="body2"><strong>Balance</strong></Typography>
            <TableContainer>
              <Table size="small" sx={{ borderCollapse: 'collapse' }}>
                <TableBody>
                  <TableRow >
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Long-term bucket:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>${data.longTermBucketInput.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Medium-term bucket:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>${data.mediumTermBucketInput.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem' }}>Short-term bucket:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem' }}>${data.shortTermBucketInput.toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Total:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem', fontWeight: 'bold', borderBottom: 'none' }}>${totalBalance.toLocaleString()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper elevation={0} sx={{ p: 1, m: 1, backgroundColor: "#F2F2F2" }}>
            <Typography variant="body2"><strong>Retirement plan</strong></Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Years to retirement:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.yearsToRetirementInput}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Target retirement growth exposure:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.targetGrowthExposureInput}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Annual drawdown rate at retirement:</TableCell>
                    <TableCell align="right" sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.annualDrawdownRateInput}%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper elevation={0} sx={{ p: 2, m: 1, backgroundColor: "#F2F2F2" }}>
            <Typography variant="body2" style={{ marginTop: '-10px' }}><strong>Investment strategy</strong></Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {data.conservativeInput > 0 && (
                    <TableRow>
                      <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Conservative:</TableCell>
                      <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.conservativeInput}%</TableCell>
                    </TableRow>
                  )}
                  {data.balancedInput > 0 && (
                    <TableRow>
                      <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Balanced:</TableCell>
                      <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.balancedInput}%</TableCell>
                    </TableRow>
                  )}
                  {data.balancedGrowthInput > 0 && (
                    <TableRow>
                      <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Balanced Growth:</TableCell>
                      <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.balancedGrowthInput}%</TableCell>
                    </TableRow>
                  )}
                  {data.growthInput > 0 && (
                    <TableRow>
                      <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Growth:</TableCell>
                      <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.growthInput}%</TableCell>
                    </TableRow>
                  )}
                  {data.highGrowthInput > 0 && (
                    <TableRow>
                      <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>High Growth:</TableCell>
                      <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.highGrowthInput}%</TableCell>
                    </TableRow>
                  )}

                  {data.otherFundAllocationInput > 0 && (
                    <TableRow>
                      <TableCell sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>Other Fund: <Typography variant='caption' sx={{ fontSize: '0.5rem' }}>Growth exposure ({data.otherFundGrowthExposureInput}%)</Typography></TableCell>
                      <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.otherFundAllocationInput}%</TableCell>
                    </TableRow>
                  )}

                  {data.otherFundAllocationInput > 0 && (
                    <TableRow>
                      <TableCell colSpan={2} sx={{ pl: 0, fontWeight: 'bold', fontSize: '0.6rem', borderBottom: 'none' }}>{data.switchToCornerstoneInput === 0 ? "Do not switch to Cornerstone" : (data.switchToCornerstoneInput === 1 ? "Glide to Cornerstone" : "Switch immediately to Cornerstone")}</TableCell>
                      {/* <TableCell align='right' sx={{ pl: 0, fontSize: '0.6rem', borderBottom: 'none' }}>{data.switchToCornerstoneInput ? "Yes" : "No"}</TableCell> */}
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Typography style={{ paddingTop: "10px", margin: "5px" }}>Adviser instructions</Typography>
        <Grid container style={{ marginLeft: '8px', marginRight: '8px' }}>
          <Grid item sm={7.9}>
            <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0' }}>
              <Table >
                <TableHead>
                  <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                    <TableCell sx={{ verticalAlign: 'top' }}><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>Bucket</Typography></TableCell>
                    <TableCell><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>Current year</Typography><Typography variant='caption' sx={{ fontSize: '0.5rem' }}>{currentYearCaption}</Typography></TableCell>
                    <TableCell ><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>Rebalance in 12 months</Typography><Typography variant='caption' sx={{ fontSize: '0.5rem' }}>Where the client wants to be</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ verticalAlign: 'top' }}>
                    <TableCell sx={{ verticalAlign: 'top' }}><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>Long-term</Typography></TableCell>
                    {isNewClient ?
                      (<TableCell>
                        <TableContainer >
                          <Table size='small'>
                            <TableBody>
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }} align='center'>${(data.longTermBucketInput - 5000 * 2).toLocaleString()}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>)
                      :
                      (<TableCell>
                        <TableContainer >
                          <Table size='small'>
                            <TableBody>
                              {results.OtherFund[0] > 0 && (
                                <TableRow >
                                  <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }}>Other Fund</TableCell>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.OtherFund[0]}%</Typography></TableCell>
                                </TableRow>
                              )}
                              {results.HighGrowth[0] > 0 && (
                                <TableRow >
                                  <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }}>High Growth</TableCell>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.HighGrowth[0]}%</Typography></TableCell>
                                </TableRow>
                              )}
                              {results.Growth[0] > 0 && (
                                <TableRow >
                                  <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }}>Growth</TableCell>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.Growth[0]}%</Typography></TableCell>
                                </TableRow>
                              )}
                              {results.BalancedGrowth[0] > 0 && (
                                <TableRow >
                                  <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }}>Balanced Growth</TableCell>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.BalancedGrowth[0]}%</Typography></TableCell>
                                </TableRow>
                              )}
                              {results.Balanced[0] > 0 && (
                                <TableRow >
                                  <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }}>Balanced</TableCell>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.Balanced[0]}%</Typography></TableCell>
                                </TableRow>
                              )}
                              {results.Conservative[0] > 0 && (
                                <TableRow >
                                  <TableCell sx={{ p: 0, m: 0, border: 0, fontSize: '0.6rem' }}>Conservative</TableCell>
                                  <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.Conservative[0]}%</Typography></TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>)}
                    <TableCell align='right'>
                      <TableContainer >
                        <Table size='small'>
                          <TableBody>
                            {results.OtherFund[1] > 0 && (
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography sx={{ fontSize: '0.6rem' }}>Other Fund</Typography></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.OtherFund[1]}%</Typography></TableCell>
                              </TableRow>
                            )}
                            {results.HighGrowth[1] > 0 && (
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography sx={{ fontSize: '0.6rem' }}>High Growth</Typography></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.HighGrowth[1]}%</Typography></TableCell>
                              </TableRow>
                            )}
                            {results.Growth[1] > 0 && (
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography sx={{ fontSize: '0.6rem' }}>Growth</Typography></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.Growth[1]}%</Typography></TableCell>
                              </TableRow>
                            )}
                            {results.BalancedGrowth[1] > 0 && (
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography sx={{ fontSize: '0.6rem' }}>Balanced Growth</Typography></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.BalancedGrowth[1]}%</Typography></TableCell>
                              </TableRow>
                            )}
                            {results.Balanced[1] > 0 && (
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography sx={{ fontSize: '0.6rem' }}>Balanced</Typography></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.Balanced[1]}%</Typography></TableCell>
                              </TableRow>
                            )}
                            {results.Conservative[1] > 0 && (
                              <TableRow >
                                <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography sx={{ fontSize: '0.6rem' }}>Conservative</Typography></TableCell>
                                <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.Conservative[1]}%</Typography></TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>Medium-term</Typography></TableCell>
                    {isNewClient ?
                      (<TableCell align='center'><Typography sx={{ fontSize: '0.6rem' }}>$5,000</Typography></TableCell>)
                      : (<TableCell align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.mediumTermData[0]}%</Typography></TableCell>)}
                    <TableCell align='right'> <Typography sx={{ fontSize: '0.6rem' }}>{results.mediumTermData[1]}%</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>Short-term</Typography></TableCell>
                    {isNewClient ?
                      (<TableCell align='center'><Typography sx={{ fontSize: '0.6rem' }}>$5,000</Typography></TableCell>)
                      : (<TableCell align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.shortTermData[0]}%</Typography></TableCell>)}
                    <TableCell align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.shortTermData[1]}%</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: blueGrey[50] }}>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '0.6rem' }} >Growth Exposure</TableCell>
                    {isNewClient ?
                      (<TableCell align='center'><Typography sx={{ fontSize: '0.6rem' }}></Typography></TableCell>)
                      : (<TableCell align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.actualGrowthExposure[0]}%</Typography></TableCell>)}
                    <TableCell align='right'><Typography sx={{ fontSize: '0.6rem' }}>{results.actualGrowthExposure[1]}%</Typography></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={3.8} style={{ marginLeft: '18px' }}>
            <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                    <TableCell colSpan={2} style={{ width: '100%' }}><Typography sx={{ fontWeight: 'bold', fontSize: '0.6rem' }} >Adviser instructions - set buy profile to:</Typography><Typography variant='caption' sx={{ fontSize: '0.5rem' }}>How to get there</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontSize: '0.6rem' }}>Long-term</TableCell>
                    <TableCell align='right' sx={{ fontSize: '0.6rem' }}>{results.AdvisorBuyProfile[0]}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontSize: '0.6rem' }}>Medium-term</TableCell>
                    <TableCell align='right' sx={{ fontSize: '0.6rem' }}>{results.AdvisorBuyProfile[1]}%</TableCell>
                  </TableRow>
                  <TableRow sx={{ borderBottom: '0px solid #E0E0E0' }}>
                    <TableCell sx={{ fontSize: '0.6rem' }}>Short-term</TableCell>
                    <TableCell align='right' sx={{ fontSize: '0.6rem' }}>{results.AdvisorBuyProfile[2]}%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item sm={12} style={{ marginLeft: '8px' }}>
          {isNewClient && <Typography variant="body2" sx={{ fontSize: '0.6rem', marginTop: '10px' }}><sup><strong>&dagger;</strong></sup>As client is starting on the glide path, open the short and medium-term managed portfolios. Transfer the minimum investment amount (currently $5,000) to both portfolios from the long-term portfolio and set the buy profile as instructed above.</Typography>}
          <Typography variant="body2" sx={{ fontSize: '0.6rem', marginTop: '10px' }} ><strong>Note: </strong>If annual contributions exceed or are expected to exceed 15% of balance at the start of the year, review destination of contributions with adviser.</Typography>
        </Grid>

        {/* <Grid sx={{marginBottom: '250px'}} item lg={7}>
        </Grid> */}
      </Grid>
      <Grid container sx={{ p: 1, height: "980px"}}>
        <Grid item sm={12} sx={{ backgroundColor: "#FFF", p: 1, marginTop: 4 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" style={{ paddingTop: "10px", marginLeft: "5px" }}>Retirement Calculator</Typography>
            <img src="/cornerstone-logo.png" alt="Cornerstone" width="254px" height="46px" />
          </Box>
        </Grid>

        <Grid item lg={8}>
          <Typography sx={{ ml: 1, marginLeft: "15px" }}>Projected Asset Allocation</Typography>
          <Typography variant="body2" sx={{ fontSize: '0.6rem', marginTop: '10px', marginLeft: '20%' }} >Asset allocation glide path as your client approaches retirement</Typography>
          <div id="chart1" style={{ height: 275 }}>
            <PersonalisedGlidepathAreaChart
              longTermData={longTermData}
              mediumTermData={mediumTermData}
              shortTermData={shortTermData}
              timeToRetirement={timeToRetirement}
            />
          </div>
        </Grid>
        <Grid item lg={4} style={{ marginTop: '10px' }}>
          <Typography sx={{ ml: 1, marginLeft: "15px", visibility: 'hidden' }}>.</Typography>
          <Typography variant="body2" sx={{ fontSize: '0.6rem', marginTop: '6px', marginLeft: '20%' }} >Asset allocation at retirement</Typography>
          <AtRetirementPieChart
            shortTermData={shortTermData[shortTermData.length - 1]}
            mediumTermData={mediumTermData[mediumTermData.length - 1]}
            longTermData={longTermData[longTermData.length - 1]} />
        </Grid>
        <Grid item lg={12} style={{ marginTop: -10, marginLeft: "15px", marginRight: "15px" }}>
          <Box sx={{ mt: 2 }}>
            <PersonalisedGlidePathTable
              small
              actualGrowthExposure={actualGrowthExposure}
              longTermData={longTermData}
              mediumTermData={mediumTermData}
              shortTermData={shortTermData}
              timeToRetirement={timeToRetirement}
              AwayFromTargetWarning={results.AwayFromTargetWarning} />
          </Box>

          <Typography sx={{ mt: 5, marginBottom: '5px' }}>Within the long-term bucket</Typography>
          <WithinLongTermBucket
            small={true}
            Conservative={Conservative}
            Balanced={Balanced}
            BalancedGrowth={BalancedGrowth}
            Growth={Growth}
            HighGrowth={HighGrowth}
            OtherFund={OtherFund}
            timeToRetirement={timeToRetirement}
            AlertMessage={results.AlertMessage}
          />
        </Grid>
      </Grid>
      {/* <Grid container sx={{ p: 1 }}>
        <Grid item sm={12} sx={{ backgroundColor: "#FFF" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" style={{ paddingTop: "10px", marginLeft: "5px" }}>Retirement Calculator</Typography>
            <img src="/cornerstone-logo.png" alt="Cornerstone" width="254px" height="46px" />
          </Box>
          <Typography style={{ paddingTop: "10px", marginLeft: "5px", marginTop: "5px" }}>Disclaimer</Typography>
        </Grid>

        <Box sx={{ pl: 1, pr: 1, pt: 0 }}>
          <Grid container>
            <Grid item sm={12}>
              <Disclaimer variant={'caption'} isPdf={true} />
            </Grid>
          </Grid>
        </Box>

      </Grid> */}
    </>
  );
}

export default PDFContent;
