// ProjectedGlidepathTable.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Alert} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const WithinLongTermBucket = ({small, timeToRetirement, Conservative, Balanced, BalancedGrowth, Growth, HighGrowth, OtherFund, AlertMessage }) => {
  return (
    <>{AlertMessage != '' && (<Alert severity="warning" sx={{mt:1, mb:1, fontSize:'0.8rem'}}> {AlertMessage}</Alert>)}
        <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0' }}>
          <Table size={small ? "small" : "medium"}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                <TableCell sx={{ fontWeight: 'bold', fontSize: small ? '0.6rem' : '1rem', width: '25%' }}>Years to retirement</TableCell>
                {timeToRetirement.map((year, index) => (
                  <TableCell key={index} sx={{ fontWeight: 'bold', fontSize: small ? '0.6rem' : '1rem' }}>{year}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {OtherFund.some(value => value !== 0) && (
                <TableRow>
                  <TableCell sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Other Fund</TableCell>
                  {OtherFund.map((data, index) => (
                    <TableCell key={index}  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                  ))}
                </TableRow>
              )}
              {HighGrowth.some(value => value !== 0) && (
                <TableRow>
                  <TableCell sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Cornerstone High Growth</TableCell>
                  {HighGrowth.map((data, index) => (
                    <TableCell key={index}  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                  ))}
                </TableRow>
              )}
              {Growth.some(value => value !== 0) && (
                <TableRow>
                  <TableCell  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Cornerstone Growth</TableCell>
                  {Growth.map((data, index) => (
                    <TableCell key={index}  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                  ))}
                </TableRow>
              )}
              {BalancedGrowth.some(value => value !== 0) && (
                <TableRow>
                  <TableCell  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Cornerstone Balanced Growth</TableCell>
                  {BalancedGrowth.map((data, index) => (
                    <TableCell key={index}  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                  ))}
                </TableRow>
              )}
              {Balanced.some(value => value !== 0) && (
                <TableRow>
                  <TableCell  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Cornerstone Balanced</TableCell>
                  {Balanced.map((data, index) => (
                    <TableCell key={index}  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                  ))}
                </TableRow>
              )}
              {Conservative.some(value => value !== 0) && (
                <TableRow>
                  <TableCell  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Cornerstone Conservative</TableCell>
                  {Conservative.map((data, index) => (
                    <TableCell key={index}  sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </>
  );
};

export default WithinLongTermBucket;
