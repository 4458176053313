// generatePDF.js
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import React from 'react';
import { createRoot } from 'react-dom/client';
import PDFContent from './PDFContent';
import PDFContentAtRetirement from './PDFContentAtRetirement';

export const generatePdf = ({pdfData, results, isAtRetirement, isNewClient, marketSignal, timeToRetirement}) => {
  const doc = new jsPDF('p', 'mm', 'a4', true);

  const addFooter = fileName => {
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);
      doc.text(fileName, 7, 290, { align: 'left' })
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.getWidth()/2, 290, { align: 'center' })
      doc.text('For adviser use only', doc.internal.pageSize.getWidth()-7, 290, { align: 'right' })
    }
  }

  // Create a temporary element to render the HTML
  const tempElement = document.createElement('div');
  tempElement.style.width = '210mm'; // Set the width to match A4 width in mm
  tempElement.style.padding = '10px';
  document.body.appendChild(tempElement);  

  // Render the PDFContent component using createRoot
  const container = document.createElement('div');
  tempElement.appendChild(container);
  const root = createRoot(container);

  //Generate name of the PDF 
  //YYYY-MM-DD_{client name}_PreRetirementReport.pdf
  //YYYY-MM-DD_{client name}_AtRetirementReport.pdf
  const getFileName = () => { 
    const clientName = isAtRetirement ? pdfData?.inputs?.clientName : pdfData?.clientName
    const clientNameInFile = clientName.replace(" ", '_')
    
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so add 1
    const day = String(now.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    const suffix = isAtRetirement ? "AtRetirementReport.pdf" : "PreRetirementReport.pdf";
    
    const fileName = `${formattedDate}_${clientNameInFile}_${suffix}`;

    return fileName;
  }


  root.render(
    isAtRetirement
    ?
    <PDFContentAtRetirement 
      pdfData={pdfData}
      marketSignal={marketSignal}
    />
    :
    <PDFContent
      actualGrowthExposure={results.actualGrowthExposure}
      longTermData={results.longTermData}
      mediumTermData={results.mediumTermData}
      shortTermData={results.shortTermData}
      timeToRetirement={timeToRetirement}
      Conservative={results.Conservative}
      Balanced={results.Balanced}
      BalancedGrowth={results.BalancedGrowth}
      Growth={results.Growth}
      HighGrowth={results.HighGrowth}
      OtherFund={results.OtherFund}
      pdfData={pdfData}
      results={results}
      isNewClient={isNewClient}
      marketSignal={marketSignal}
    />
  );

  // Use a timeout to ensure the chart is fully rendered before capturing
  setTimeout(() => {
    html2canvas(tempElement,  {scale: "2"}).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 0.3);
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;     

      const fileName = getFileName();

      if (imgHeight <= pdfHeight) {
        // If the image height is less than or equal to the PDF page height, add it to a single page
        doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, imgHeight, undefined,'FAST');
      } else {
        // If the image height exceeds the PDF page height, split it across two pages
        const pageHeight = pdfHeight;
        let yPosition = 0;
        while (yPosition < imgHeight) {
          doc.addImage(imgData, 'PNG', 0, -yPosition, pdfWidth, imgHeight, undefined, 'FAST');
          yPosition += pageHeight;
          if (yPosition < imgHeight) {
            doc.addPage();
          }
        }
      }
      
      addFooter(fileName);
      doc.save(fileName);

      // Remove the temporary element
      document.body.removeChild(tempElement);
    });
  }, 1000); // Adjust timeout as necessary
};
