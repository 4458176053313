// ProjectedGlidepathTable.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Alert} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const PersonalisedGlidePathTable = ({small, actualGrowthExposure, longTermData, mediumTermData, shortTermData, timeToRetirement, AwayFromTargetWarning }) => {
  return (
    <>
    {AwayFromTargetWarning != '' && (<Alert severity="warning" sx={{mt:1, mb:1, fontSize:'0.8rem'}}> {AwayFromTargetWarning}</Alert>)}
        <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0' }}>
          <Table size={small ? "small" : "medium"}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                <TableCell sx={{ fontWeight: 'bold', fontSize: small ? '0.6rem' : '1rem', width: '25%' }}>Years to retirement</TableCell>
                {timeToRetirement.map((year, index) => (
                  <TableCell key={index} sx={{ fontWeight: 'bold', fontSize: small ? '0.6rem' : '1rem' }} >{year}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ backgroundColor: blueGrey[50] }}>
                <TableCell sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Actual Growth Exposure</TableCell>
                {actualGrowthExposure.map((data, index) => (
                  <TableCell key={index} sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Long-term bucket</TableCell>
                {longTermData.map((data, index) => (
                  <TableCell key={index} sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Medium-term bucket</TableCell>
                {mediumTermData.map((data, index) => (
                  <TableCell key={index} sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontSize: small ? '0.6rem' : '1rem' }}>Short-term bucket</TableCell>
                {shortTermData.map((data, index) => (
                  <TableCell key={index} sx={{ fontSize: small ? '0.6rem' : '1rem' }}>{data}%</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </>
  );
};

export default PersonalisedGlidePathTable;
