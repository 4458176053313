import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Tabs, Tab, Box, Button, Alert } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import RecipeIcon from '@mui/icons-material/Receipt';
import { BeachAccess } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ClientInputs from './components/ClientInputs';
import CurrentRetirementMarketSignals from './components/CurrentRetirementMarketSignal';
import { BarChart } from '@mui/x-charts/BarChart';
import { generatePdf } from './components/generatePDF';
import PersonalisedGlidepathAreaChart from './components/PersonalisedGlidePathAreaChart';
import AtRetirementPieChart from './components/AtRetirementPieChart';
import DownloadIcon from '@mui/icons-material/Download';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PersonalisedGlidePathTable from './components/PersonalisedGlidePathTable';
import WithinLongTermBucket from './components/withinLongTermBucketTable';
import InvestmentInstructionsTable from './components/InvestmentInstructionsTable';
import RunCalculations from './components/RunCalculations';
import Disclaimer from './components/Disclaimer';

const theme = createTheme({
  palette: {
    secondary: {
      main: grey[300], // This is the blueGrey color
    },
  },
});

/* START MUI BarChart*/
let longTermBarChart = {
  data: [21],
  label: 'Long-term bucket',
};
let mediumTermBarChart = {
  data: [39],
  label: 'Medium-term bucket',
};
let shortTermBarChart = {
  data: [40],
  label: 'Short-term bucket',
};

/* Preset Projected Asset Allocation Data*/
let timeToRetirement = ['Year 7', 'Year 6', 'Year 5', 'Year 4', 'Year 3', 'Year 2', 'Year 1', 'Retirement'];

let actualGrowthExposure = [98, 93, 89, 84, 79, 74, 70, 65]
let longTermData = [100, 94, 87, 80, 74, 67, 60, 54];
let mediumTermData = [0, 4, 9, 13, 17, 22, 27, 31];
let shortTermData = [0, 2, 4, 7, 9, 11, 13, 15];

let atRetirementTableCurrentLongTerm = 0
let atRetirementTableCurrentMediumTerm = 0
let atRetirementTableCurrentShortTerm = 0

let atRetirementTableCurrentConservative = 0
let atRetirementTableCurrentBalanced = 0
let atRetirementTableCurrentBalancedGrowth = 0
let atRetirementTableCurrentGrowth = 0
let atRetirementTableCurrentHighGrowth = 0

let atRetirementTableSwitchLongTermData = 51
let atRetirementTableSwitchMediumTermData = 31
let atRetirementTableSwitchShortTermData = 15

let atRetirementTableSwitchConservative = 0
let atRetirementTableSwitchBalanced = 0
let atRetirementTableSwitchBalancedGrowth = 0
let atRetirementTableSwitchGrowth = 0
let atRetirementTableSwitchHighGrowth = 0

let myEnvVar = process.env.REACT_APP_VERSION;
// green/red market signal is configured in .env file
let envMarketSignal = process.env.REACT_APP_MARKET_SIGNAL.toLowerCase();

let calculationError = null


let Conservative = [100, 93, 87, 80, 74, 67, 60, 54]
let Balanced = [0, 0, 0, 0, 0, 0, 0, 0]
let BalancedGrowth = [0, 0, 0, 0, 0, 0, 0, 0]
let Growth = [0, 0, 0, 0, 0, 0, 0, 0]
let HighGrowth = [0, 0, 0, 0, 0, 0, 0, 0]
let OtherFund = [0, 0, 0, 0, 0, 0, 0, 0]
let AdvisorBuyProfile = [0, 0, 0]
/* END Projected Asset Allocation Data */

let AlertMessage = '';
let AwayFromTargetWarning = '';

function App() {

  const initialInputState = {
    clientName: '',
    adviserName: '',
    conservativeInput: 0,
    balancedInput: 0,
    balancedGrowthInput: 0,
    growthInput: 0,
    highGrowthInput: 0,
    otherFundsGrowthInput: 0,
    growthExpostureType: 'Cornerstone',
    longTermBucketInput: 0,
    mediumTermBucketInput: 0,
    shortTermBucketInput: 0,
    annualDrawdownRateInput: 0,
    yearsToRetirementInput: 0,
    targetGrowthExposureInput: 0,
    otherFundAllocationInput: 0,
    otherFundGrowthExposureInput: 0,
    switchToCornerstoneInput: 0
  };

  const [inputs, setInputs] = useState({
    clientName: '',
    adviserName: '',
    conservativeInput: 0,
    balancedInput: 0,
    balancedGrowthInput: 0,
    growthInput: 0,
    highGrowthInput: 0,
    otherFundsGrowthInput: 0,
    growthExpostureType: 'Cornerstone',
    longTermBucketInput: 0,
    mediumTermBucketInput: 0,
    shortTermBucketInput: 0,
    annualDrawdownRateInput: 0,
    yearsToRetirementInput: 0,
    targetGrowthExposureInput: 0,
    otherFundAllocationInput: 0,
    otherFundGrowthExposureInput: 0,
    switchToCornerstoneInput: 0
  });

  const [results, setResults] = useState({
    timeToRetirement: [],
    actualGrowthExposure: [],
    longTermData: [],
    mediumTermData: [],
    shortTermData: [],
    Conservative: [],
    Balanced: [],
    BalancedGrowth: [],
    Growth: [],
    HighGrowth: [],
    OtherFund: [],
    AdvisorBuyProfile: [],
    AlertMessage:'',
    AwayFromTargetWarning: ''
  });

  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState('panel0');
  const [selectedExposureType, setSelectedExposureType] = React.useState('Cornerstone');

  const [isGenerated, setIsGenerated] = useState(false);
  const [isBlurPreRetirement, setIsBlurPreRetirement] = React.useState(true);
  const [isBlurAtRetirement, setIsBlurAtRetirement] = React.useState(true);
  const [isInputInvalid, setIsInputInvalid] = React.useState(true);
  const [isNewClient, setIsNewClient] = React.useState(true);
  const [pdfData, setPDFData] = React.useState({})
  const [pdfDataAtRetirement, setPDFDataAtRetirement] = React.useState({})



  // useEffect immediately updates the Inputs values when inputs are changed. useState doesn't immediately update the new value
  useEffect(() => {
    // determin if it is a new client or an existing client
    if (inputs.mediumTermBucketInput === 0 && inputs.shortTermBucketInput === 0) {
      setIsNewClient(true)
    }
    else setIsNewClient(false)

    let long_term_cornerstone_total = inputs.conservativeInput + inputs.balancedInput + inputs.balancedGrowthInput + inputs.growthInput + inputs.highGrowthInput + inputs.otherFundAllocationInput;
    let current_balance_total = inputs.longTermBucketInput + inputs.mediumTermBucketInput + inputs.shortTermBucketInput;

    if (inputs.adviserName === '' || inputs.clientName === '' || current_balance_total === 0 || (inputs.growthExpostureType === "Cornerstone" && long_term_cornerstone_total !== 100) || inputs.targetGrowthExposureInput === 0)
      setIsInputInvalid(true);

    else {
      setIsInputInvalid(false);
    }

    if (inputs.growthExpostureType === "Cornerstone") {
      setSelectedExposureType('Cornerstone');
      // handleInputChange('otherFundsGrowthInput')(0); // reset other growth exposure input to 0
    }

    else {

      setSelectedExposureType('Other');

      // reset cornerstone inputs to 0;
      // handleInputChange('conservativeInput')(0)
      // handleInputChange('balancedInput')(0)
      // handleInputChange('balancedGrowthInput')(0)
      // handleInputChange('growthInput')(0)
      // handleInputChange('highGrowthInput')(0)
    }
  }, [inputs]); // Specify state.value as a dependency

  //Function to handle input changes
  const handleInputChange = (inputName) => (value) => {
    if (!isBlurPreRetirement)
      setIsBlurPreRetirement(true);
    if (!isBlurAtRetirement)
      setIsBlurAtRetirement(true);
    setInputs(prevInputs => ({
      ...prevInputs,
      [inputName]: value

    }));
  };

  const normalizeTo100 = (arrays) => {
    const length = arrays[0].length; // Assume all arrays are of the same length
    for (let i = 0; i < length; i++) {
      let sum = arrays.reduce((acc, arr) => acc + arr[i], 0); // Calculate the sum of each index position
      if (sum !== 100) {
        //arrays[0][i] += (100 - sum); 
        // Adjust the first array to make the total sum 100  (find the first array that has a value greater than zero in that position)
        for (let x = 0; x < length; x++) {
          if (arrays[x][i] > 0) {
            arrays[x][i] += (100 - sum); // Adjust to make the total sum 100
            break;
          }
        }
      }
    }
    return arrays;
  };

  const convertToPercentage = (array) => {
    if (!array || !Array.isArray(array)) {
      console.error("Input is undefined or not an array.");
      return { error: "Input is undefined or not an array." };
    }
    try {
      return array.map(value => Math.round(value * 100));
    } catch (error) {
      console.error("Error in convertToPercentage:", error);
      return { error: "An error occurred in convertToPercentage." };
    }
  };

  // Function to handle the 'Generate' button click
  const generateAtRetirement = () => {
    inputs.yearsToRetirementInput = 2
    const calculationResults = RunCalculations(inputs);
    setResults(calculationResults);
    setIsGenerated(true)

    if (calculationResults.error) {
      setIsBlurAtRetirement(true);
      calculationError = calculationResults.error
      return;
    } else {
      calculationError = null
    }

    // console.log("Calculation results:", calculationResults)

    // console.log("Calc LT:", calculationResults.LongTermBucket[2] * 100)
    // console.log("Calc MT:", calculationResults.MediumTermBucket[2] * 100)
    // console.log("Calc ST:", calculationResults.ShortTermBucket[2] * 100)

    //Convert atRetirementTable data to percentage values
    atRetirementTableCurrentLongTerm = (inputs.longTermBucketInput / (inputs.longTermBucketInput + inputs.mediumTermBucketInput + inputs.shortTermBucketInput))
    atRetirementTableCurrentMediumTerm = Math.round((inputs.mediumTermBucketInput / (inputs.longTermBucketInput + inputs.mediumTermBucketInput + inputs.shortTermBucketInput)) * 100)
    atRetirementTableCurrentShortTerm = Math.round((inputs.shortTermBucketInput / (inputs.longTermBucketInput + inputs.mediumTermBucketInput + inputs.shortTermBucketInput)) * 100)

    atRetirementTableCurrentConservative = Math.round(inputs.conservativeInput * atRetirementTableCurrentLongTerm)
    atRetirementTableCurrentBalanced = Math.round(inputs.balancedInput * atRetirementTableCurrentLongTerm)
    atRetirementTableCurrentBalancedGrowth = Math.round(inputs.balancedGrowthInput * atRetirementTableCurrentLongTerm)
    atRetirementTableCurrentGrowth = Math.round(inputs.growthInput * atRetirementTableCurrentLongTerm)
    atRetirementTableCurrentHighGrowth = Math.round(inputs.highGrowthInput * atRetirementTableCurrentLongTerm)

    //Normalize Current Data
    //Normalize this result to 100
    let sum = atRetirementTableCurrentConservative + atRetirementTableCurrentBalanced + atRetirementTableCurrentBalancedGrowth + atRetirementTableCurrentGrowth
      + atRetirementTableCurrentHighGrowth + atRetirementTableCurrentMediumTerm + atRetirementTableCurrentShortTerm;
    let diff = 100 - sum;
    atRetirementTableCurrentShortTerm = atRetirementTableCurrentShortTerm + diff;

    atRetirementTableSwitchLongTermData = (calculationResults.LongTermBucket[2] * 100)
    atRetirementTableSwitchMediumTermData = Math.round(calculationResults.MediumTermBucket[2] * 100)
    atRetirementTableSwitchShortTermData = Math.round(calculationResults.ShortTermBucket[2] * 100)

    atRetirementTableSwitchConservative = Math.round(calculationResults.Conservative[2] * 100)  //* atRetirementLongTermData
    atRetirementTableSwitchBalanced = Math.round(calculationResults.Balanced[2] * 100)
    atRetirementTableSwitchBalancedGrowth = Math.round(calculationResults.BalancedGrowth[2] * 100)
    atRetirementTableSwitchGrowth = Math.round(calculationResults.Growth[2] * 100)
    atRetirementTableSwitchHighGrowth = Math.round(calculationResults.HighGrowth[2] * 100)

    //Normalize Switch Data (Advisor Instructions)
    //Normalize this result to 100
    sum = atRetirementTableSwitchConservative + atRetirementTableSwitchBalanced + atRetirementTableSwitchBalancedGrowth + atRetirementTableSwitchGrowth
      + atRetirementTableSwitchHighGrowth + atRetirementTableSwitchMediumTermData + atRetirementTableSwitchShortTermData;
    diff = 100 - sum;
    atRetirementTableSwitchShortTermData = atRetirementTableSwitchShortTermData + diff;

    longTermBarChart = {
      label: 'Long-term bucket',
      data: [atRetirementTableSwitchLongTermData]
    }
    mediumTermBarChart = {
      label: 'Medium-term bucket',
      data: [atRetirementTableSwitchMediumTermData]
    }
    shortTermBarChart = {
      label: 'Short-term bucket',
      data: [atRetirementTableSwitchShortTermData]
    }
    setIsBlurAtRetirement(false);

    let pdfData = {
      longTermBarChart,
      mediumTermBarChart,
      shortTermBarChart,
      selectedExposureType,
      atRetirementTableCurrentHighGrowth,
      atRetirementTableCurrentGrowth,
      atRetirementTableCurrentBalancedGrowth,
      atRetirementTableCurrentBalanced,
      atRetirementTableCurrentConservative,
      atRetirementTableCurrentMediumTerm,
      atRetirementTableCurrentShortTerm,
      atRetirementTableSwitchHighGrowth,
      atRetirementTableSwitchGrowth,
      atRetirementTableSwitchBalancedGrowth,
      atRetirementTableSwitchBalanced,
      atRetirementTableSwitchConservative,
      atRetirementTableSwitchMediumTermData,
      atRetirementTableSwitchShortTermData,
      inputs
    }

    setPDFDataAtRetirement(pdfData)
  }

  const generatePreRetirement = () => {
    const calculationResults = RunCalculations(inputs);
    setResults(calculationResults);
    setIsGenerated(true)

    if (calculationResults.error) {
      setIsBlurPreRetirement(true);
      calculationError = calculationResults.error
      return;
    } else {
      calculationError = null
    }

    const handleNullOrUndefined = (data) => {
      return data ? convertToPercentage(data) : [0];
    };


    timeToRetirement = calculationResults.Glidepath || [0];
    // Loop through timeToRetirement and change the first element to "Current", and the last element to "Retirement" 
    timeToRetirement = timeToRetirement.map((year, index) => {
      if (year === "Year 0") {
        return "Retirement";
      } else if (index === 0) {
        return "Current";
      }
      return year;
    }); 

    actualGrowthExposure = handleNullOrUndefined(calculationResults.ActualGrowthExposure);
    longTermData = handleNullOrUndefined(calculationResults.LongTermBucket);
    mediumTermData = handleNullOrUndefined(calculationResults.MediumTermBucket);
    shortTermData = handleNullOrUndefined(calculationResults.ShortTermBucket);
    Conservative = handleNullOrUndefined(calculationResults.Conservative);
    Balanced = handleNullOrUndefined(calculationResults.Balanced);
    BalancedGrowth = handleNullOrUndefined(calculationResults.BalancedGrowth);
    Growth = handleNullOrUndefined(calculationResults.Growth);
    HighGrowth = handleNullOrUndefined(calculationResults.HighGrowth);
    OtherFund = handleNullOrUndefined(calculationResults.OtherFund);
    AdvisorBuyProfile = handleNullOrUndefined(calculationResults.AdvisorBuyProfile);
    AlertMessage = calculationResults.AlertMessage;
    AwayFromTargetWarning = calculationResults.AwayFromTargetWarning;

    // Convert and normalise the bucket data
    const normalizedBuckets = normalizeTo100([longTermData, mediumTermData, shortTermData]);
    longTermData = normalizedBuckets[0];
    mediumTermData = normalizedBuckets[1];
    shortTermData = normalizedBuckets[2];

    const normalizedLongTermBuckets = normalizeTo100([Conservative, Balanced, BalancedGrowth, Growth, HighGrowth, OtherFund, mediumTermData, shortTermData]);
    Conservative = normalizedLongTermBuckets[0];
    Balanced = normalizedLongTermBuckets[1];
    BalancedGrowth = normalizedLongTermBuckets[2];
    Growth = normalizedLongTermBuckets[3];
    HighGrowth = normalizedLongTermBuckets[4];
    OtherFund = normalizedLongTermBuckets[5];
    mediumTermData = normalizedLongTermBuckets[6];
    shortTermData = normalizedLongTermBuckets[7];

    setResults({
      timeToRetirement, actualGrowthExposure, longTermData, mediumTermData, shortTermData, Conservative, Balanced, BalancedGrowth, Growth, HighGrowth, OtherFund, AdvisorBuyProfile, AlertMessage, AwayFromTargetWarning
    })

    //console.log("Conservative", Conservative)
    //console.log("Balanced", Balanced)
    //console.log("BalancedGrowth", BalancedGrowth)
    ///console.log("Growth", Growth)
    ///console.log("HighGrowth", HighGrowth)
    setIsBlurPreRetirement(false);
    setPDFData(inputs)
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setIsBlurPreRetirement(true);
    setIsBlurAtRetirement(true);
    setInputs(initialInputState);
    setIsInputInvalid(true);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleExposureTypeChange = (newValue) => {
    setSelectedExposureType(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 0, backgroundColor: blueGrey[100] }}>
        <Box maxWidth='1950px' mx="auto" >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant='h4'> Retirement Calculator</Typography>
          </Grid>
          <Grid item>
            <img src="/cornerstone-logo.png" alt="Company Logo" style={{ height: '50px', paddingTop: '15px' }} />
          </Grid>
        </Grid>
        {/* Tabs */}
        <Grid item xs={12}>
          <Tabs value={tabValue} onChange={handleTabChange} left="true">
            <Tab
              icon={<RecipeIcon />}
              iconPosition="start"
              label="Pre Retirement"
              sx={{
                '&.Mui-selected': {
                  backgroundColor: blueGrey[50],
                  color: 'black',
                },
                borderRadius: '5px 5px 0 0', // This line makes the tabs curved
              }}
            />
            <Tab label="At Retirement"
              icon={<BeachAccess />}
              iconPosition="start"
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                borderRadius: '5px 5px 0 0', // This line makes the tabs curved
              }} />
          </Tabs>
        </Grid>
        <Paper elevation={5} style={{ backgroundColor: blueGrey[50] }}>
          <Grid container>
            {/* Conditional content based on the selected tab */}
            {tabValue === 0 && (
              <>
                <Grid item xs={12} sm={3} md={3}>
                  <ClientInputs
                    expanded={expanded}
                    selectedExposureType={selectedExposureType}
                    handleExposureTypeChange={handleExposureTypeChange}
                    handleAccordionChange={handleAccordionChange}
                    displayOther="Other"
                    visibleAccordions={{
                      currentBalance: true,
                      investmentStrategy: true,
                      retirementInputs: true
                    }}
                    hideYears={false}
                    handleInputChange={handleInputChange}
                    tabValue={tabValue}
                  />
                  <Box sx={{ pl: 2, pr: 2 }}>
                    <Button variant='contained' sx={{ mt: 3 }} fullWidth disabled={isInputInvalid} onClick={generatePreRetirement}>Generate</Button>
                  </Box>
                </Grid>
                {/* Investment Instructions Section */}
                <Grid item xs={12} sm={9} md={9}>
                  <Box sx={{ p: 1 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Investment Instructions
                      </Typography>
                      <Button disabled={!isGenerated} variant="contained" onClick={() => generatePdf({ pdfData, results, isAtRetirement: false, isNewClient, marketSignal: envMarketSignal, timeToRetirement })} startIcon={<DownloadIcon />} color="secondary">Download PDF</Button>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                      <CurrentRetirementMarketSignals marketSignal={envMarketSignal} isAtRetirement={false} />
                    </Box>
                    {calculationError ? <Alert severity='error' variant='filled' sx={{ mb: 3 }}>{calculationError}</Alert> : null}
                    <Paper elevation={2} sx={{ p: 5, filter: isBlurPreRetirement ? 'blur(8px)' : 'none', transition: 'filter 0.3s' }}>
                      <Grid container spacing={2}>
                        <InvestmentInstructionsTable
                          Conservative={Conservative}
                          Balanced={Balanced}
                          BalancedGrowth={BalancedGrowth}
                          Growth={Growth}
                          HighGrowth={HighGrowth}
                          OtherFund={OtherFund}
                          actualGrowthExposure={actualGrowthExposure}
                          longTermData={longTermData}
                          mediumTermData={mediumTermData}
                          shortTermData={shortTermData}
                          isNewClient={isNewClient}
                          longTermBucketInput={inputs.longTermBucketInput}
                          selectedExposureType={selectedExposureType}
                          AdvisorBuyProfile={AdvisorBuyProfile} />
                      </Grid>
                    </Paper>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Projected Asset Allocation</Typography>
                    <Paper elevation={2} sx={{ p: 3, filter: isBlurPreRetirement ? 'blur(8px)' : 'none', transition: 'filter 0.3s' }}>
                      <Grid container>
                        <Grid item sm={8}>
                          <Typography>Personalised glide path</Typography>
                          <PersonalisedGlidepathAreaChart
                            longTermData={longTermData}
                            mediumTermData={mediumTermData}
                            shortTermData={shortTermData}
                            timeToRetirement={timeToRetirement}
                            skipAnimation={false} />
                        </Grid>
                        <Grid item sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography>Asset allocation at retirement</Typography>
                          <Box sx={{ml:12}}>
                          <AtRetirementPieChart
                            shortTermData={shortTermData[shortTermData.length - 1]}
                            mediumTermData={mediumTermData[mediumTermData.length - 1]}
                            longTermData={longTermData[longTermData.length - 1]} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={12}>
                          <Box sx={{ mt: 3 }}>
                            <PersonalisedGlidePathTable 
                            actualGrowthExposure={actualGrowthExposure} 
                            longTermData={longTermData} 
                            mediumTermData={mediumTermData} 
                            shortTermData={shortTermData} 
                            timeToRetirement={timeToRetirement}
                            AwayFromTargetWarning={AwayFromTargetWarning} />
                          </Box>

                          <Box sx={{ mt: 3 }}>
                            <Typography sx={{ mt: 3 }}>Within the long-term bucket</Typography>
                            <WithinLongTermBucket
                              Conservative={Conservative}
                              Balanced={Balanced}
                              BalancedGrowth={BalancedGrowth}
                              Growth={Growth}
                              HighGrowth={HighGrowth}
                              OtherFund={OtherFund}
                              timeToRetirement={timeToRetirement}
                              AlertMessage={AlertMessage}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      {/* <Grid container>
                        <Grid item sm={12}>
                          <Disclaimer variant={'caption'} isPdf={false} />
                        </Grid>
                      </Grid> */}

                    </Paper>
                  </Box>
                </Grid>
              </>
            )}
            {tabValue === 1 && (
              <>
                {/* At Retirement Client Inputs" */}
                <Grid item xs={12} sm={3} md={3}>
                  <ClientInputs
                    expanded={expanded}
                    selectedExposureType={selectedExposureType}
                    handleExposureTypeChange={handleExposureTypeChange}
                    handleAccordionChange={handleAccordionChange}
                    visibleAccordions={{
                      currentBalance: true,
                      investmentStrategy: true,
                      retirementInputs: true
                    }}
                    hideYears={true}
                    handleInputChange={handleInputChange}
                    tabValue={tabValue}
                  />
                  <Box sx={{ pl: 2, pr: 2 }}>
                    <Button variant='contained' sx={{ mt: 3 }} fullWidth disabled={isInputInvalid} onClick={generateAtRetirement}>Generate</Button>
                  </Box>
                </Grid>
                {/* Portfolio composition at retirement */}
                <Grid item xs={12} sm={9} md={9}>
                  <Box sx={{ p: 1 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6" sx={{ mb: 2 }}>Portfolio composition at retirement</Typography>
                      <Button disabled={!isGenerated} variant="contained" onClick={() => generatePdf({ pdfData: pdfDataAtRetirement, results: inputs, isAtRetirement: true, marketSignal: envMarketSignal })} startIcon={<DownloadIcon />} color="secondary">Download PDF</Button>
                    </Box>
                    <CurrentRetirementMarketSignals marketSignal={envMarketSignal} isAtRetirement />
                    {calculationError ? <Alert severity='error' variant='filled' sx={{ mb: 2, mt: 2 }}>{calculationError}</Alert> : null}
                    <Paper elevation={2} sx={{ p: 3, filter: isBlurAtRetirement ? 'blur(8px)' : 'none', transition: 'filter 0.3s', mt: 3 }}>
                      <Grid container spacing={2}>
                        <Grid item sm={4.1}>
                          <BarChart
                            /*slotProps={{ legend: { hidden: true } }}*/
                            height={500}
                            series={[
                              { ...longTermBarChart, stack: 'total', color: '#394F87' },
                              { ...mediumTermBarChart, stack: 'total', color: '#2196F3' },
                              { ...shortTermBarChart, stack: 'total', color: '#A9A8A8' },
                            ]}
                            slotProps={{
                              legend: {
                                labelStyle: { fontSize: 10 }
                              }
                            }}
                            bottomAxis={null}
                          />
                        </Grid>
                        <Grid item sm={7.9}>
                          <TableContainer sx={{ borderRadius: 2, border: '1px solid #E0E0E0' }}>
                            <Table >
                              <TableHead>
                                <TableRow sx={{ backgroundColor: blueGrey[100] }}>
                                  <TableCell sx={{ verticalAlign: 'top' }}><Typography sx={{ fontWeight: 'bold' }}>Bucket</Typography></TableCell>
                                  <TableCell sx={{ verticalAlign: 'top' }}><Typography sx={{ fontWeight: 'bold' }}>Current</Typography><Typography variant='caption'>Where client is now</Typography></TableCell>
                                  <TableCell sx={{ verticalAlign: 'top' }}><Typography sx={{ fontWeight: 'bold' }}>Adviser instructions: switch to</Typography><Typography variant='caption'>Where client wants to be</Typography></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell sx={{ verticalAlign: 'top' }}><Typography>Long-term bucket</Typography></TableCell>
                                  <TableCell sx={{ verticalAlign: 'top' }}>
                                    <TableContainer >
                                      <Table size='small'>
                                        <TableBody>
                                          {atRetirementTableCurrentHighGrowth > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }}><Typography>High Growth</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }} align='right'><Typography>{atRetirementTableCurrentHighGrowth}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableCurrentGrowth > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }}><Typography>Growth</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }} align='right'><Typography>{atRetirementTableCurrentGrowth}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableCurrentBalancedGrowth > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }}><Typography>Balanced Growth</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }} align='right'><Typography>{atRetirementTableCurrentBalancedGrowth}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableCurrentBalanced > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }}><Typography>Balanced</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }} align='right'><Typography>{atRetirementTableCurrentBalanced}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableCurrentConservative > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }}><Typography>Conservative</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0, verticalAlign: 'top' }} align='right'><Typography>{atRetirementTableCurrentConservative}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </TableCell>
                                  <TableCell align='right' sx={{ verticalAlign: 'top' }}>
                                    <TableContainer >
                                      <Table size='small'>
                                        <TableBody>
                                          {atRetirementTableSwitchHighGrowth > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography>High Growth</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography>{atRetirementTableSwitchHighGrowth}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableSwitchGrowth > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography>Growth</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography>{atRetirementTableSwitchGrowth}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableSwitchBalancedGrowth > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography>Balanced Growth</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography>{atRetirementTableSwitchBalancedGrowth}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableSwitchBalanced > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography>Balanced</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography>{atRetirementTableSwitchBalanced}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                          {atRetirementTableSwitchConservative > 0 && (
                                            <TableRow >
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }}><Typography>Conservative</Typography></TableCell>
                                              <TableCell sx={{ p: 0, m: 0, border: 0 }} align='right'><Typography>{atRetirementTableSwitchConservative}%</Typography></TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                    <Typography variant='caption'>Investment Growth</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><Typography>Medium-term bucket</Typography></TableCell>
                                  <TableCell align='right'><Typography>{atRetirementTableCurrentMediumTerm}%</Typography></TableCell>
                                  <TableCell align='right'>
                                    <Typography>{atRetirementTableSwitchMediumTermData}%</Typography>
                                    <Typography variant='caption'>~{(atRetirementTableSwitchMediumTermData / inputs.annualDrawdownRateInput).toFixed(1)} Years Stable Income</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell><Typography>Short-term bucket</Typography></TableCell>
                                  <TableCell align='right'><Typography>{atRetirementTableCurrentShortTerm}%</Typography></TableCell>
                                  <TableCell align='right'><Typography>{atRetirementTableSwitchShortTermData}%</Typography>
                                    <Typography variant='caption'>~3 Years Spending</Typography></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>

                        {/* <Box sx={{ p: 2 }}>
                          <Grid container>
                            <Grid item sm={12}>
                              <Disclaimer variant={'caption'} isPdf={false} />
                            </Grid>
                          </Grid>
                        </Box> */}


                        {/* <Grid item sm={12}>
                          <Typography variant='caption'><strong>Important information </strong></Typography>
                          <Typography variant='caption'>This projection tool is made available to Invest Blue Pty Ltd (Invest Blue) and its representatives by Russell Investment Management Ltd (RIM), in its capacity as investment consultant to Invest Blue, pursuant to a Consulting Agreement, dated on or about 23 December 2020 (as amended) (the “Agreement”). Under the Agreement, Invest Blue agrees not to and will ensure that its representatives do not:
                              <ul>
                                <li><Typography variant='caption'>provide the projection tool (including the outworkings or reports produced by it) to any retail client; and</Typography></li>
                                <li><Typography variant='caption'>provide financial advice to a retail client using the projection tool without due consideration to the appropriateness of the outworkings or reports of the projection tool to the personal circumstances of the retail client, in order to comply with relevant laws, including but not limited to the best interests duty in the provision of personal advice to retail clients.</Typography></li>
                              </ul>
                            </Typography>
                            <Typography variant='caption'>The intention of the projection tool is to assist Invest Blue and its representatives in formulating their financial advice to clients, by providing guidance on how to blend time horizon portfolios. </Typography>
                            <Typography variant='caption'>The calculator makes certain assumptions that may not be appropriate for all clients. </Typography>
                            <Typography variant='caption'>Invest Blue and its representatives assume full responsibility for determining the suitability and fitness of any outworkings or reports produced by the projection tool. The outworkings or reports produced by the projection tool do not constitute professional or financial advice to any clients of Invest Blue and its representatives, and do not contain any recommendations to invest in any Russell Investments product. The projection tool has not been prepared having regard to any client's objectives, financial situation or needs.  Invest Blue and its representatives are not affiliates or representatives of RIM.</Typography>
                            <Typography variant='caption'>The projection tool is made available by RIM to Invest Blue and its representatives on an “as is” basis until 30th November 2023, prior to which a replacement projection tool will be made available. To the extent permitted by law, RIM provides no warranties or guarantees, express or implied, and accepts no liability for any loss or damage arising from use of the projection tool, its outworkings or reports, or reliance on them.</Typography>
                            <Typography variant='caption'>This work is copyright 2023. Apart from any use permitted under the Copyright Act 1968, no part may be reproduced by any process, nor may any other exclusive right be exercised, without the permission of RIM. </Typography>
                        </Grid> */}
                      </Grid>
                    </Paper>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>

        {/*  Used to design and build printable version  */}
        {/* <Box sx={{ width: "210mm", pt: 10 }}> */}
        {/* <PDFContent
            longTermBucketInput={inputs.longTermBucketInput}
            mediumTermBucketInput={inputs.mediumTermBucketInput}
            shortTermBucketInput={inputs.shortTermBucketInput}
            actualGrowthExposure={actualGrowthExposure}
            longTermData={longTermData}
            mediumTermData={mediumTermData}
            shortTermData={shortTermData}
            timeToRetirement={timeToRetirement}
            Conservative={Conservative}
            Balanced={Balanced}
            BalancedGrowth={BalancedGrowth}
            Growth={Growth}
            HighGrowth={HighGrowth}
            pdfData={inputs}
            results={results}
          /> */}
        {/* <PDFContentAtRetirement 
        pdfData={pdfDataAtRetirement}
        />  */}
        {/* </Box> */}

        <Typography variant='caption'>   © 2024 - Russell Investments - {myEnvVar}</Typography>
        </Box>
      </Box>

    </ThemeProvider>
  );
}

export default App;
