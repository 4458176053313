export const CONSTANTS = {

    CURRENT_MARKET_SIGNAL: 'Current market signal',
    ADVISER_INSTRUCTIONS: 'Adviser instructions - ',
    CONFIGURE_PLATFORM_AS_BELOW: 'Configure platform as below:',
    SELL_PROFILE: 'Sell Profile: Short-term bucket',
    BUY_PROFILE: 'Buy Profile: Strategic Asset Allocation',
    DISTRIBUTIONS: 'Distributions: Reinvest',
    ANNUALLY_REBALANCE: 'Annually rebalance to target allocation',
    WHAT_IS_GREEN_MARKET_SIGNAL: 'What is a green market signal?',
    GREEN_MARKET_SIGNAL_DESC: 'Markets are currently conducive to following the normal discipline of selling down the profits from the medium and long-term buckets to top up the reduced balance in the short-term bucket due to pension drawdowns being funded from this fund.',
    WHAT_IS_RED_MARKET_SIGNAL: 'What is a red market signal?',
    RED_MARKET_SIGNAL_DESC: 'Now is not a good time to be selling down from the medium and long-term buckets due to recent market falls and subsequent growth market undervaluation. Continue to draw down from the short-term bucket to meet all income needs and defer rebalancing until markets return to a more stable environment (indicated by the return to a Green market signal).',
    ANNUALLY_REBALANCE_RED: 'No annual rebalance under red market signal',
    PRE_BUY_PROFILE: 'Buy Profile: Medium and short-term bucket',
    PRE_GREEN_MARKET_SIGNAL_DESC: 'Markets are currently conducive to following the normal discipline of a gradual reduction in growth assets as you are nearing retirement. Rebalancing should be undertaken annually along the prescribed glide path with ongoing contributions paid to the medium and short-term buckets.',
    PRE_RED_MARKET_SIGNAL_DESC: 'Now is not a good time to be selling down from the medium and long-term buckets due to recent market falls and subsequent growth market undervaluation. Stop rebalancing and defer progression along the glide path. and direct ongoing contributions to the current Strategic Asset Allocation. These actions should be continued until markets return to a more stable environment (indicated by the return to a green market signal).',
    Warning_More_Than_5_Away: 'You are more than 5% away from your target growth exposure at retirement. Please consider adjusting your retirement targets, or switching to Cornerstone pre-retirement.',
    Warning_More_Than_10_Away: 'Using your other fund allocation, you are more than 10% away from your target growth exposure at retirement. We have automatically moved you into a Cornerstone portfolio. Please consider adjusting your at retirement targets to avoid this.',
    Unable_To_Reach_Target: 'We were unable to reach your target solution with your current long term bucket allocation. Please consider adjusting your at retirement inputs.',
    Warning_Glide_To_CStone: 'Note: You have opted to glide to a Cornerstone portfolio in long-term bucket pre-retirement, consider whether this is the optimal choice for your client before proceeding.',
    Warning_Switch_Immediately: 'Note: You have opted to switch your whole long-term bucket to Cornerstone pre-retirement immediately, consider whether this is the optimal choice for your client before proceeding.',
    Warning_1_Away_From_Target: 'Warning: you are more than 1% away from your target growth exposure at retirement. Please consider adjusting your inputs.'
}