import React from "react";
import { Paper, Typography, Stack, Chip, Grid } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { CONSTANTS } from "../utils/constants";

const small =  '0.65rem' 

const PDFCurrentRetMarketSignal = ({ marketSignal, isAtRetirement }) => {
  return (
    <>
      {marketSignal === "green" && (
        <Grid item sm={12}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              m: 1, 
              backgroundColor: green[100],
              border: '0.5px solid #4caf50',
              color: "#1b5e20"
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body2"
                gutterBottom
                sx={{ fontWeight: "bold", fontSize: '0.7rem' }}
              >
                {CONSTANTS.CURRENT_MARKET_SIGNAL}
              </Typography>
              <Chip
                label="Green"
                color="primary"
                variant="outlined"
                sx={{
                  borderColor: green[900],
                  color: "#FFF",
                  backgroundColor: green[900],
                  fontSize: small,
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography component="div" sx={{ width: "40%" }}>
                <Typography variant="body2" sx={{ fontSize: small }}>
                  <strong>{CONSTANTS.ADVISER_INSTRUCTIONS}</strong>{" "}
                  {CONSTANTS.CONFIGURE_PLATFORM_AS_BELOW}
                </Typography>
                <Typography component="div" sx={{ marginLeft: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {CONSTANTS.SELL_PROFILE}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {isAtRetirement ? CONSTANTS.BUY_PROFILE : CONSTANTS.PRE_BUY_PROFILE}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {CONSTANTS.DISTRIBUTIONS}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {CONSTANTS.ANNUALLY_REBALANCE}
                  </Typography>
                </Typography>
              </Typography>
              <Typography component="div" sx={{ width: "60%" }}>
                <Typography variant="body2" sx={{ fontSize: small }}>
                  <strong>{CONSTANTS.WHAT_IS_GREEN_MARKET_SIGNAL}</strong>
                </Typography>
                <Typography variant="body2" sx={{ fontSize: small }}>
                  {isAtRetirement ? CONSTANTS.GREEN_MARKET_SIGNAL_DESC : CONSTANTS.PRE_GREEN_MARKET_SIGNAL_DESC}
                </Typography>
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      )}
      {marketSignal === "red" && (
        <Grid item sm={12}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              m: 1, 
              backgroundColor: red[100],
              border: '0.5px solid #b71c1c',
              color: '#b71c1c'
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body2"
                gutterBottom
                sx={{ fontWeight: "bold", fontSize: '0.7rem' }}
              >
                {CONSTANTS.CURRENT_MARKET_SIGNAL}
              </Typography>
              <Chip
                label="Red"
                color="primary"
                variant="outlined"
                sx={{
                  borderColor: red[900],
                  color: "#FFF",
                  backgroundColor: red[900],
                  fontSize: small,
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-around">
              <Typography component="div" sx={{ width: "40%" }}>
                <Typography variant="body2" sx={{ fontSize: small }}>
                  <strong>{CONSTANTS.ADVISER_INSTRUCTIONS}</strong>{" "}
                  {CONSTANTS.CONFIGURE_PLATFORM_AS_BELOW}
                </Typography>
                <Typography component="div" sx={{ marginLeft: 1 }}>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {CONSTANTS.SELL_PROFILE}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • { isAtRetirement ? CONSTANTS.BUY_PROFILE : CONSTANTS.PRE_BUY_PROFILE}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {CONSTANTS.DISTRIBUTIONS}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: small }}>
                    • {CONSTANTS.ANNUALLY_REBALANCE_RED}
                  </Typography>
                </Typography>
              </Typography>
              <Typography component="div" sx={{ width: "60%" }}>
                <Typography variant="body2" sx={{ fontSize: small }}>
                  <strong>{CONSTANTS.WHAT_IS_RED_MARKET_SIGNAL}</strong>
                </Typography>
                <Typography variant="body2" sx={{ fontSize: small }}>
                  {isAtRetirement ? CONSTANTS.RED_MARKET_SIGNAL_DESC : CONSTANTS.PRE_RED_MARKET_SIGNAL_DESC}
                </Typography>
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default PDFCurrentRetMarketSignal;
