import _ from 'lodash';
import { CONSTANTS } from '../utils/constants';

const RunCalculations = (inputs) => {
    // EV: Re-write based on new Python sent by Tom.
    try {

		// DO some basic input validations
		if (inputs.yearsToRetirementInput === 0)
		{
			return {
				error: "Please use the At retirement calculator"
			};
		}
        // Growth exposures  - rates for different strategies
        const CONSERVATIVE_GROWTH = 0.32;
        const BALANCED_GROWTH = 0.53;
        const BALANCEDGRO_GROWTH = 0.705;
        const GROWTH_GROWTH = 0.86;
        const HIGHGROWTH_GROWTH = 0.98;
        const MEDIUMT_BUCKET_GROWTH = 0.4;
        const SHORTT_BUCKET_GROWTH = 0;

		// get Other Fund Growth Exposure from Client Input
		let Other_Fund_Growth = inputs.otherFundGrowthExposureInput / 100;

		//# Create a list containing the growth exposures (to loop through)
		const GROWTH_EXPOSURES = [CONSERVATIVE_GROWTH, BALANCED_GROWTH, BALANCEDGRO_GROWTH, GROWTH_GROWTH, HIGHGROWTH_GROWTH, Other_Fund_Growth];

		// FEES
        const PORT_FEES = [0.0059, 0.0059, 0.0069, 0.0064, 0.0058, 0];   //, 0.0107

        // Portfolio NAMES
        const PORT_NAMES = ['Conservative', 'Balanced', 'Balanced Growth', 'Growth', 'High Growth', 'Other Fund'];


		//Set local variables from the user Inputs.
		//# Current allocations
		let longTermBucket = inputs.longTermBucketInput;
        let mediumTermBucket = inputs.mediumTermBucketInput;
        let shortTermBucket = inputs.shortTermBucketInput;

		//# Annual drawdown at retirement
        let annualDrawDown = inputs.annualDrawdownRateInput / 100;

		//# Glidepath length
        let yearsToRetirement = inputs.yearsToRetirementInput;

		//# Target growth exposure at retirement
		let targetGrowthExp = inputs.targetGrowthExposureInput / 100;

		//# Breakdown of Long-term bucket

        let cons = inputs.conservativeInput / 100;
        let bal = inputs.balancedInput / 100;
        let bg = inputs.balancedGrowthInput / 100;
        let g = inputs.growthInput / 100;
        let hg = inputs.highGrowthInput / 100;
        let other_funds_alloc = inputs.otherFundAllocationInput / 100;
		let switchToCornerstoneInput = inputs.switchToCornerstoneInput;

        /*

        let current_ltp = longTermBucket / (longTermBucket + mediumTermBucket + shortTermBucket);
        let current_mtp = mediumTermBucket / (longTermBucket + mediumTermBucket + shortTermBucket);
        let current_stp = shortTermBucket / (longTermBucket + mediumTermBucket + shortTermBucket);

        const cons_plevel = cons * current_ltp;
        const bal_plevel = bal * current_ltp;
        const bg_plevel = bg * current_ltp;
        const g_plevel = g * current_ltp;
        const hg_plevel = hg * current_ltp;
        const other_funds_plevel = other_funds * current_ltp;
		*/

		//# Finding weights and Ranges for MT and LT Bucket

		let stBucket = 3 * annualDrawDown;
		let mtLower = 3 * annualDrawDown;
		let mtUpper = 7 * annualDrawDown;

		//# Create a template list
		const rng = _.range(mtLower, mtUpper, 0.0001);

		//# Create a template dataframe with all the choices
		let template = rng.map(value => ({
			"ST Bucket Allocation": stBucket,
			"MT Bucket Allocation": value,
			"LT Bucket Allocation": 1 - stBucket - value,
		}));

		//# loop through all potential buckets
		const results_mt = [];
		const results_lt = [];
		const results_growthExp = [];
		const results_fees = [];
		let idx = 0;

		GROWTH_EXPOSURES.forEach((i, idx) => {
			template.forEach(row => {
				row['Growth Exposure - Delta'] = Math.abs((row["MT Bucket Allocation"] * MEDIUMT_BUCKET_GROWTH) + (row["LT Bucket Allocation"] * i) - targetGrowthExp);
			});

			const min_index = template.reduce((minIdx, row, currentIdx) => row['Growth Exposure - Delta'] < template[minIdx]['Growth Exposure - Delta'] ? currentIdx : minIdx, 0);

			const mtBuckOpt = template[min_index]["MT Bucket Allocation"];
			const ltBuckOpt = template[min_index]["LT Bucket Allocation"];
			const growthExpCalc = template[min_index]["Growth Exposure - Delta"];

			results_mt.push(mtBuckOpt);
			results_lt.push(ltBuckOpt);
			results_growthExp.push(growthExpCalc);
			results_fees.push((stBucket * 0.38) + (mtBuckOpt * 0.76) + (ltBuckOpt * PORT_FEES[idx]));
		});

		// Build a choice table
		let choiceTable = PORT_NAMES.map((name, index) => ({
			Portfolio: name,
			"MT %": results_mt[index],
			"LT %": results_lt[index],
			"Growth Exp": results_growthExp[index],
			"Fee Consideration": results_fees[index],
		}));

		// Now we need to choose the bucket FIRST that minimises us having to change buckets
		let currentLtAlloc = [cons, bal, bg, g, hg, other_funds_alloc];

		// Calculate the sum of currentLtAlloc
		const ct = currentLtAlloc.reduce((sum, value) => sum + value, 0);
		
		choiceTable.forEach((row, index) => {
			row["Current Alloc"] = currentLtAlloc[index];
		});

		// Perform a test, boot out if there are no viable solutions

		// new logic
		if (other_funds_alloc > 0)
		{
			choiceTable = choiceTable.filter(row => row["Growth Exp"] <= 0.1);
		}
		else {
			choiceTable = choiceTable.filter(row => row["Growth Exp"] <= 0.05);
		}

		// end of new logic

		if (choiceTable.length === 0) {
			console.log("Change Target Allocation or Annual Drawdown Rate at Retirement %");
			return {
				error: "Calculator cannot find appropriate glide path for these retirement inputs, please change Target Allocation or Annual Drawdown Rate at Retirement %"
			};
		}

		// Sort choiceTable by "Current Alloc" (descending), "Growth Exp" (ascending), and "Fee Consideration" (ascending)
		choiceTable.sort((a, b) => {
			if (b["Current Alloc"] !== a["Current Alloc"]) {
				return b["Current Alloc"] - a["Current Alloc"];
			}
			if (a["Growth Exp"] !== b["Growth Exp"]) {
				return a["Growth Exp"] - b["Growth Exp"];
			}
			return a["Fee Consideration"] - b["Fee Consideration"];
		});

        // console.log(choiceTable); // Assuming result is defined elsewhere

		// Other Fund logic below:
		let ischoice = [];
		let cstone_fund = [];
		if (choiceTable.length >= 2) {
			if (choiceTable[0].Portfolio === "Other Fund") {
				cstone_fund.push(choiceTable[1].Portfolio)
				ischoice.push(1);
			}
		}

		if (ischoice.length === 0) {
			ischoice.push(0);
			cstone_fund.push(0);
			console.warn("USER HAS NO CHOICE BUT TO CONVERT TO A CORNERSTONE SOLUTION PLEASE ENTER A WARNING HERE");
		}

		ischoice = ischoice[0];
		cstone_fund = cstone_fund[0];

		let choiceTable2 = _.clone(choiceTable);
		choiceTable2 = choiceTable2.filter(row => row.Portfolio !== "Other Fund");
		const choiceTable2_name = choiceTable2[0].Portfolio;
		choiceTable2 = choiceTable2.filter(row => row.Portfolio === choiceTable2_name);

		let choiceTable3 = _.clone(choiceTable);
		choiceTable3 = choiceTable3.filter(row => row.Portfolio !== "Other Fund");
		const choiceTable3_name = choiceTable3[0].Portfolio;
		choiceTable3 = choiceTable3.filter(row => row.Portfolio === choiceTable3_name);

		if(ischoice === 1)
		{
			// 0 means "Do not switch", 1 means "Glide to CStone", 2 means "Switch immediately to CStone"
			if(switchToCornerstoneInput === 1)
			{
				// Assign the filtered array back to choiceTable
				choiceTable = choiceTable2;
			}

			else if (switchToCornerstoneInput === 2)
				{
				const sum_current_ltalloc = ct;
				choiceTable = choiceTable2;
				choiceTable[0]["Current Alloc"] = sum_current_ltalloc; 
				other_funds_alloc = 0;
				
				// Create a DataFrame-like structure
				let cta = PORT_NAMES.map(name => ({ name, lta: 0 }));
				const cfund = choiceTable[0].Portfolio;

				// Iterate through the DataFrame-like structure and update values
				for (let i = 0; i < cta.length; i++) {
					if (cta[i].name === cfund) {
						cta[i].lta = sum_current_ltalloc;
					}
					}

				// Extract the relevant column values into a list
				currentLtAlloc = cta.map(row => row.lta);

			}
		}

		else if (ischoice === 0 && other_funds_alloc > 0) {
			if(switchToCornerstoneInput === 1)
			{
				// Assign the filtered array back to choiceTable
				choiceTable = choiceTable3;
			}

			else if (switchToCornerstoneInput === 2)
			{
				const sum_current_ltalloc = ct;
				choiceTable = choiceTable3;
				choiceTable[0]["Current Alloc"] = sum_current_ltalloc;
				other_funds_alloc = 0;

				// Create a DataFrame-like structure
				let cta = PORT_NAMES.map(name => ({ name, lta: 0 }));
				const cfund = choiceTable[0].Portfolio;

				// Iterate through the DataFrame-like structure and update values
				for (let i = 0; i < cta.length; i++) {
					if (cta[i].name === cfund) {
						cta[i].lta = sum_current_ltalloc;
					}
				}

				// Extract the relevant column values into a list
				currentLtAlloc = cta.map(row => row.lta);
			}
		}

		// Now we have our target portfolio
		const TargetLongTermPortfolio = choiceTable[0].Portfolio;
		const TargetLongTermPortfolioAlloc = choiceTable[0]["LT %"];
		const TargetMediumTerPortfolioAlloc = choiceTable[0]["MT %"];
		const TargetShortTermPortfolioAlloc = 1 - TargetLongTermPortfolioAlloc - TargetMediumTerPortfolioAlloc;

		// Create an export [this is so you can see where we are and what is happening]
		const names_1 = ['Long-term Portfolio', "Medium-term Portfolio", 'Short-term Portfolio'];
		const weights = [TargetLongTermPortfolioAlloc, TargetMediumTerPortfolioAlloc, TargetShortTermPortfolioAlloc];

		const result = names_1.map((name, index) => ({
			Portfolio: name,
			Weights: weights[index],
		}));

		//console.log("TARGET LONG TERM PORTFOLIO: " + TargetLongTermPortfolio);

		//console.log(result); // Assuming result is defined elsewhere

		//new logic
		let AlertMessage = "";

		if(switchToCornerstoneInput === 1)
			AlertMessage = CONSTANTS.Warning_Glide_To_CStone

		else if (switchToCornerstoneInput === 2)
			AlertMessage = CONSTANTS.Warning_Switch_Immediately

		else{
			if (TargetLongTermPortfolio === "Other Fund") {
				if (choiceTable[0]["Growth Exp"] > 0.05 && choiceTable[0]["Growth Exp"] < 0.1) {
					AlertMessage = CONSTANTS.Warning_More_Than_5_Away;
					// console.warn("! Warning: You are more than 5% away from your target growth exposure at retirement. Please consider adjusting your retirement targets, or switching to cornerstone pre-retirement");
				} 
				else if (choiceTable[0]["Growth Exp"] < 0.05) {
					AlertMessage = "";
				}
			}
	
			else {
				if (other_funds_alloc > 0) {
					AlertMessage = CONSTANTS.Warning_More_Than_10_Away;
					// console.warn("! Warning: Using your other fund allocation, you are more than 10% away from your target growth exposure at retirement. We have automatically moved you into a cornerstone portfolio. Please consider adjusting your at retirement targets to avoid this.");
				}
				else if (choiceTable[0]["Current Alloc"] === 0) { 
					AlertMessage = CONSTANTS.Unable_To_Reach_Target;
					// console.warn("We were unable to reach your target solution with your current long term bucket allocation. Please consider adjusting your at retirement inputs.");
				}
				else{AlertMessage = "";}
			}
		}
		

		//end of new logic


		// Now find the starting allocations

		let longTermBucket_alloc = longTermBucket / (longTermBucket + mediumTermBucket + shortTermBucket);
		let mediumTermBucket_alloc = mediumTermBucket / (longTermBucket + mediumTermBucket + shortTermBucket);
		let shortTermBucket_alloc = shortTermBucket / (longTermBucket + mediumTermBucket + shortTermBucket);

		let currentAlloc = [longTermBucket_alloc, mediumTermBucket_alloc, shortTermBucket_alloc];

		// First create the year to retirement list

		let t_n = Array.from({length: yearsToRetirement + 1}, (_, i) => i).reverse();
		let t_n_truncate = t_n.slice(1);
        let t_n_v = Array.from({length: yearsToRetirement + 1}, (_, i) => 0);

		// Build out the data frame

		//let portfolioLevel = new DataFrame([[0, 0, 0, 0]], {columns: [String(t_n[0])]});
		let index_names = ["Actual Growth Exposure", "Long-term Alloc", "Medium-term Alloc", "Short-term Alloc"];
        //EV - Replacing a dataframe with a object array that has the same data
        let portfolioLevel = index_names.map((name, index) => ({
			"IdxName": name,
            "colName" : Array.from({length: yearsToRetirement + 1}, (_, i) => i).reverse(),
            "colValues" : Array.from({length: yearsToRetirement + 1}, (_, i) => 0)
		}));

        /*for (let i of t_n_truncate) {
			portfolioLevel[String(i)] = [0, 0, 0, 0];
		}
        portfolioLevel.forEach((row, index) => {
			row["Idx Name"] = index_names[index];
		});        */
		//portfolioLevel[String(yearsToRetirement)] = index_names;
		//portfolioLevel = portfolioLevel.set_index(portfolioLevel[String(yearsToRetirement)]);
		//portfolioLevel[String(yearsToRetirement)] = 0;
		//portfolioLevel.index.name = null;


		// print export to show you where we are

		//console.log(portfolioLevel);

		// Now we need to input our starting portfolio and ending portfolio

		for (let i = 1; i < portfolioLevel.length; i++) {
			portfolioLevel[i].colValues[0] = currentAlloc[i - 1];
			portfolioLevel[i].colValues[portfolioLevel[i].colValues.length -1] = weights[i - 1];
		}

		// again, printing portfolio level to see where we are

		//console.log(portfolioLevel);

		// Now we need to fill the range between the two

		let differences = [];
		for (let i = 1; i < portfolioLevel.length; i++) {
			differences.push((portfolioLevel[i].colValues[portfolioLevel[i].colValues.length -1]
                - portfolioLevel[i].colValues[0]) / yearsToRetirement);
		}

		// Now we need to fill in the values in the export table

		for (let c = 1; c < portfolioLevel.length; c++) {
			for (let i = 1; i < portfolioLevel[c].colValues.length - 1; i++) {
				portfolioLevel[c].colValues[i] = portfolioLevel[c].colValues[i-1] + differences[c - 1];
			}
		}

		// Print, see where we are

		//console.log(portfolioLevel);

		// Now we need to do the long within long-term pool glide path, start with an empty dataframe. This is essentially the same process

		//let longTermBucketLevel = new DataFrame(PORT_NAMES, {columns: [String(yearsToRetirement)]});

        //EV - Replacing a dataframe with a object array that has the same data
        let longTermBucketLevel = PORT_NAMES.map((name, index) => ({
            "PortName": name,
            "colName" : Array.from({length: yearsToRetirement + 1}, (_, i) => i).reverse(),
            "colValues" : Array.from({length: yearsToRetirement + 1}, (_, i) => 0)
        }));

		/*for (let i of t_n_truncate) {
			longTermBucketLevel[String(i)] = [0, 0, 0, 0, 0];
		}*/

		for (let i = 0; i < longTermBucketLevel.length; i++) {
			if (longTermBucketLevel[i].PortName === TargetLongTermPortfolio) {
				longTermBucketLevel[i].colValues[longTermBucketLevel[i].colValues.length -1] = 1;
			} else {
				longTermBucketLevel[i].colValues[longTermBucketLevel[i].colValues.length -1] = 0;
			}
		}

        for (let i = 0; i < longTermBucketLevel.length; i++) {
			longTermBucketLevel[i].colValues[0] = currentLtAlloc[i];
		}

		//longTermBucketLevel = longTermBucketLevel.set_index(longTermBucketLevel[String(yearsToRetirement)]);
		//longTermBucketLevel[String(yearsToRetirement)] = currentLtAlloc;
		//longTermBucketLevel.index.name = null;

		// Now implement the same process as before

		differences = [];
		for (let i = 1; i < longTermBucketLevel.length; i++) {
			differences.push((longTermBucketLevel[i].colValues[longTermBucketLevel[i].colValues.length -1]
                - longTermBucketLevel[i].colValues[0]) / yearsToRetirement);
		}

		for (let c = 1; c < longTermBucketLevel.length; c++) {
			for (let i = 1; i < longTermBucketLevel[c].colValues.length - 1; i++) {
				longTermBucketLevel[c].colValues[i] = longTermBucketLevel[c].colValues[i-1] + differences[c - 1];
			}
		}

		let lt_g = [];
		for (let c = 0; c < longTermBucketLevel[0].colValues.length; c++) {
			let time_t = [];
			for (let i = 0; i < longTermBucketLevel.length; i++) {
				time_t.push(longTermBucketLevel[i].colValues[c] * GROWTH_EXPOSURES[i]);
			}
			lt_g.push(time_t.reduce((a, b) => a + b, 0));
		}

		for (let i = 0; i < portfolioLevel[0].colValues.length; i++) {
			let all = (portfolioLevel[2].colValues[i] * MEDIUMT_BUCKET_GROWTH) +
            (portfolioLevel[3].colValues[i] * SHORTT_BUCKET_GROWTH) + (portfolioLevel[1].colValues[i] * lt_g[i]);
			portfolioLevel[0].colValues[i] = all;
		}

		// display the final output
        const gpLength = yearsToRetirement + 1;
        const time = Array.from({ length: gpLength }, (_, i) => `Year ${gpLength - i - 1}`);


		//console.log(longTermBucketLevel);
		//console.log(portfolioLevel);

		/*### NEW STUFF

		weights = portfolioLevel.T
		weights = list(weights["Long-term Alloc"])
		*/

		let lt_weights = [];
		for (let i = 0; i < portfolioLevel[1].colValues.length; i++) {
			lt_weights.push(portfolioLevel[1].colValues[i]);
		}

		/*-
		for c in range(0,len(weights)):
			for i in range(0,len(longTermBucketLevel)):
				longTermBucketLevel.iat[i,c] = longTermBucketLevel.iat[i,c] * weights[c]
		*/
		for (let c = 0; c < lt_weights.length; c++) {
			for (let i = 0; i < longTermBucketLevel.length; i++) {
				longTermBucketLevel[i].colValues[c] = longTermBucketLevel[i].colValues[c] * lt_weights[c];
			}
		}
		/*
		for c in range(1,len(weights)-1):
			for i in range(0,len(longTermBucketLevel)):
				longTermBucketLevel.iat[i,c] = 0
		*/
		for (let c = 1; c < lt_weights.length-1; c++) {
			for (let i = 0; i < longTermBucketLevel.length; i++) {
				longTermBucketLevel[i].colValues[c] = 0;
			}
		}
		/*
		differences = []
		for i in range(0,len(longTermBucketLevel)):
			differences.append((longTermBucketLevel.iat[i,0] - longTermBucketLevel.iat[i,len(longTermBucketLevel.columns)-1])/-yearsToRetirement)

		for c in range(1,len(weights)-1):
			for i in range(0,len(longTermBucketLevel)):
				longTermBucketLevel.iat[i,c] = longTermBucketLevel.iat[i,c-1] + differences[i]
		*/
		differences = []
		for (let i = 0; i < longTermBucketLevel.length; i++) {
			differences.push((longTermBucketLevel[i].colValues[0] -
				longTermBucketLevel[i].colValues[longTermBucketLevel[i].colValues.length -1]) / (yearsToRetirement * -1));
		}

		for (let c = 1; c < lt_weights.length-1; c++) {
			for (let i = 0; i < longTermBucketLevel.length; i++) {
				longTermBucketLevel[i].colValues[c] = longTermBucketLevel[i].colValues[c-1] + differences[i];
			}
		}
		/*
		# Now we do the matrix multiplcation of growth exposures and long-term bucket level

		x = []
		for c in range(0,len(longTermBucketLevel.columns)):
			y = []
			for r in range(0,len(longTermBucketLevel)):
				y.append(longTermBucketLevel.iat[r,c] * growthExposures[r])
			x.append(sum(y))

		for i in range(0,len(portfolioLevel.columns)):
			portfolioLevel.iat[0,i] = x[i] + (mt_g * portfolioLevel.iat[2,i])*/

		let matrix = []
		for (let c = 0; c < longTermBucketLevel[1].colValues.length; c++) {
			let y = []
			for (let r = 0; r < longTermBucketLevel.length; r++) {
				y.push(longTermBucketLevel[r].colValues[c] * GROWTH_EXPOSURES[r]);
			}
			matrix.push(_.sum(y));
		}

		for (let i = 0; i < portfolioLevel[1].colName.length; i++) {
			portfolioLevel[0].colValues[i] = matrix[i] + (MEDIUMT_BUCKET_GROWTH * portfolioLevel[2].colValues[i]);
		}

		// console.log( "longTermBucketLevel---->", longTermBucketLevel);
		// console.log( "portfolioLevel---->", portfolioLevel);

		/*
		directionality = pd.DataFrame(portfolioLevel.iloc[1:,0:2])
		directionality["delta"] = directionality.iloc[:,1] - directionality.iloc[:,0]
		display(directionality)
		*/

		let adv_names = ["Long-term Alloc", "Medium-term Alloc", "Short-term Alloc"];
        //EV - Replacing a dataframe with a object array that has the same data
        let buyProfile = adv_names.map((name, index) => ({
			"IdxName": name,
            "colName" : ["t2", "delta", "buysell", "buyprofile"],
            "colValues" : [0, 0,"None","None"]
		}));

		for (let i = 1; i < portfolioLevel.length; i++) {
			buyProfile[i-1].colValues[0] = (portfolioLevel[i].colValues[1]);
			buyProfile[i-1].colValues[1] = Math.round((portfolioLevel[i].colValues[1] - portfolioLevel[i].colValues[0]) * 10000) / 10000 ;
		}

		for (let i = 0; i < buyProfile.length; i++) {
			let trade = "sell";
			//check if long-term delta is up or down to determine if we need to buy or sell
			if (buyProfile[i].colValues[1]>0) { trade= "buy"}
			buyProfile[i].colValues[2] = trade ;
		}

		/*
		sumBuy = pd.DataFrame(directionality[directionality["Buy/Sell"] == "Buy"].iloc[:,1]).sum(axis=0)
		for i in range(0,len(directionality)):
			if directionality.iat[i,3] == "Buy":
				directionality.iat[i,4] = float(directionality.iat[i,1] / sumBuy)
			else:
				directionality.iat[i,4] = 0
		*/

		// SumBuy is the result of adding the t2 alloc (only for Buys of medium and short-term, ignore long-term)
		let sumBuy = _.map( buyProfile, function(item, index){
			 if (item.colValues[2] === "buy")
				{ return item.colValues[0];  }
			 else
			 { return 0; }  }).reduce((partialSum, a) => partialSum + a, 0);

		for (let i = 0; i < buyProfile.length; i++) {
			if (buyProfile[i].colValues[2] === "buy")
			{
				buyProfile[i].colValues[3] = parseFloat(buyProfile[i].colValues[0] / sumBuy);
			}
			else
			{
				buyProfile[i].colValues[3] = 0;
			}
		}

		let advisorBuyProfile = _.map( buyProfile, function(item, index){
			return item.colValues[3];  });
		// console.log( "buyProfile---->", advisorBuyProfile);

		//Return Results - Not sure if this needs to change
		const Glidepath = time;
        const ActualGrowthExposure = portfolioLevel[0].colValues;
        const LongTermBucket = portfolioLevel[1].colValues;
        const MediumTermBucket = portfolioLevel[2].colValues;
        const ShortTermBucket = portfolioLevel[3].colValues;
        const Conservative = longTermBucketLevel[0].colValues;
        const Balanced = longTermBucketLevel[1].colValues;
        const BalancedGrowth = longTermBucketLevel[2].colValues;
        const Growth = longTermBucketLevel[3].colValues;
        const HighGrowth = longTermBucketLevel[4].colValues;
		const OtherFund = longTermBucketLevel[5].colValues;
		const AdvisorBuyProfile = advisorBuyProfile;

		// Perform Final QA of results to ensure no weird outputs on the UI
		_.each(ActualGrowthExposure, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(LongTermBucket, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(MediumTermBucket, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(ShortTermBucket, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(Conservative, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(Balanced, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(BalancedGrowth, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(Growth, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(HighGrowth, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(OtherFund, function(item){
			if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});
		_.each(AdvisorBuyProfile, function(item){
		if ( item > 1.005) { throw new Error('An error occurred during the calculation. Please review the inputs.'); }});

		let AwayFromTargetWarning = "";

		// console.log(portfolioLevel);
		// New logic for test_value
		const test_value = Math.abs(Math.round(portfolioLevel[0].colValues[portfolioLevel[0].colValues.length -1] * 100 - (targetGrowthExp * 100)));

		if (test_value >= 1) {
			AwayFromTargetWarning = CONSTANTS.Warning_1_Away_From_Target;
		//   console.warn("Warning: you are more than 1% away from your target growth exposure at retirement. Please consider adjusting your inputs.");
		}
		else{
			AwayFromTargetWarning = "";
		}

        //return { Glidepath, portfolioLevel };
		return { Glidepath, ActualGrowthExposure, LongTermBucket, MediumTermBucket, ShortTermBucket, Conservative, Balanced, BalancedGrowth, Growth, HighGrowth, OtherFund, AdvisorBuyProfile, AlertMessage, AwayFromTargetWarning };

    } catch (error) {
        console.error("Error in RunCalculations.js: ", error);
        return {
            error: "An error occurred during the calculation. Please review the inputs."
        };
    }
};
export default RunCalculations;
