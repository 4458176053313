import React, { useState, useRef, useEffect } from 'react';
import { Typography, TextField, Box, Stack, InputLabel, Select, MenuItem, FormControl, FormControlLabel, Checkbox, Radio, RadioGroup, InputAdornment, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';



const ClientInputs = ({ expanded, displayOther, handleAccordionChange, visibleAccordions, handleInputChange, hideYears, tabValue }) => {
  const [targetRetirementGrowthExposure, setTargetRetirementGrowthExposure] = React.useState('');
  const [annualDrawdownRetirementRate, setAnnualDrawdownRetirementRate] = React.useState('');


  // Define state variables for each balance
  const [longTermBalance, setLongTermBalance] = useState(0);
  const [mediumTermBalance, setMediumTermBalance] = useState(0);
  const [shortTermBalance, setShortTermBalance] = useState(0);

  // Calculate total balance
  const totalBalance = longTermBalance + mediumTermBalance + shortTermBalance;

  const [concervativePercentage, setConcervativePercentage] = useState(0); //0.00
  const [balancedPercentage, setBalancedPercentage] = useState(0);
  const [balancedGrowthPercentage, setBalancedGrowthPercentage] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(0);
  const [highGrowthPercentage, setHighGrowthPercentage] = useState(0);
  const [otherFundAllocation, setOtherFundAllocation] = useState(0);
  const [otherFundGrowthExposure, setOtherFundGrowthExposure] = useState(0);
  const [switchToCornerstone, setSwitchToCornerstone] = useState(0);
  const [otherFundPercentage, setOtherFundPercentage] = useState(0);

  //Calculate Investment Stragetgy Total
  const totalInvestmentStrategy = concervativePercentage + balancedPercentage + balancedGrowthPercentage + growthPercentage + highGrowthPercentage + otherFundAllocation;

  //Years to retirement and lump sum amounts
  const [yearsToRetirement, setYearsToRetirement] = React.useState(7);
  const [lumpSumAmounts, setLumpSumAmounts] = useState(Array(yearsToRetirement).fill(0));

  const windowWidth = useRef(window.innerWidth);
  const [isWindowLarge, setWindowLarge] = React.useState(false);

  useEffect(() => {
    handleInputChange('yearsToRetirementInput')(yearsToRetirement);
    if (windowWidth.current > 2180)
      setWindowLarge(true);
  }, [isWindowLarge, yearsToRetirement]);

  const handleTargetRetirementGrowthExposure = (event) => {
    const value = Number(event.target.value);
    if (!isNaN(value)) {  // Optionally add more specific validation rules if necessary
      setTargetRetirementGrowthExposure(value); // Update local state
      handleInputChange('targetGrowthExposureInput')(value); // Update App.js state
    }
  };

  const handleAnnualDrawdownRetirementRate = (event) => {
    const value = Number(event.target.value);
    if (!isNaN(value)) {  // Optionally add more specific validation rules if necessary
      setAnnualDrawdownRetirementRate(value); // Update local state
      handleInputChange('annualDrawdownRateInput')(value); // Update App.js state
    }
  };

  const validateInput = (value) => {
    return !isNaN(value) && value >= 0 && value <= 100 && (/^\d*\.?\d{0,2}$/.test(value))
  }

  const handleRadioButtonChange = (event) => {
    const value = Number(event.target.value);
    setSwitchToCornerstone(value);
    handleInputChange('switchToCornerstoneInput')(value);
  }


  return (<Box sx={{ p: 1 }}>
    <Typography variant="h6" sx={{ mb: 2 }}>Client inputs</Typography>

    <Accordion expanded={expanded === 'panel0'} onChange={handleAccordionChange('panel0')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel0-content"
        id="panel0-header"
      >
        Client information
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={3} direction="column" width="100%">
          <TextField
            required
            label="Client Name"
            variant="standard"
            fullWidth
            margin="normal"
            onChange={e => { handleInputChange('clientName')(e.target.value) }}
          />
          <TextField
            required
            label="Adviser Name"
            variant="standard"
            fullWidth
            margin="normal"
            onChange={e => { handleInputChange('adviserName')(e.target.value) }}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>

    {visibleAccordions.currentBalance && (
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Current balance
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3} direction="column" width="100%">
            <TextField
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Typography>Long-term bucket
                    <Tooltip  title="This is the client’s long-term capital growth pool (currently invested with Cornerstone or other funds) which will gradually decrease as they glide into retirement. If clients have not commenced the bucketing strategy, allocate their funds to this bucket only.">
                      <HelpIcon sx={{fontSize:'1.2rem', ml:1, color:"grey"}}/></Tooltip> </Typography>
                </Box>
              }
              variant="standard"
              fullWidth
              margin="normal"
              placeholder="0"
              value={longTermBalance.toLocaleString()}
              onChange={e => {
                const cleanedValue = e.target.value.replace(/,/g, '');
                if (!isNaN(Number(cleanedValue))) {
                  setLongTermBalance(Number(cleanedValue));
                  handleInputChange('longTermBucketInput')(Number(cleanedValue)); // Call the handleInputChange function to update the parent state
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Typography>Medium-term bucket
                    <Tooltip  title="This is a Cornerstone portfolio designed for both capital growth and consistent income. The allocation will gradually increase as the client glides into retirement.">
                      <HelpIcon sx={{fontSize:'1.2rem', ml:1, color:"grey"}}/></Tooltip> </Typography>
                </Box>
              }
              variant="standard"
              fullWidth
              margin="normal"
              placeholder="0"
              value={mediumTermBalance.toLocaleString()}
              onChange={e => {
                const cleanedValue = e.target.value.replace(/,/g, '');
                if (!isNaN(Number(cleanedValue))) {
                  setMediumTermBalance(Number(cleanedValue));
                  handleInputChange('mediumTermBucketInput')(Number(cleanedValue));
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Typography>Short-term bucket
                    <Tooltip  title="This is a Cornerstone portfolio designed for spending. It contains no growth assets and aims to provide an income return over cash and liquidity. This allocation will gradually increase as the client glides into retirement.">
                      <HelpIcon sx={{fontSize:'1.2rem', ml:1, color:"grey"}}/></Tooltip> </Typography>
                </Box>
              }
              variant="standard"
              fullWidth
              margin="normal"
              placeholder="0"
              value={shortTermBalance.toLocaleString()}
              onChange={e => {
                const cleanedValue = e.target.value.replace(/,/g, '');
                if (!isNaN(Number(cleanedValue))) {
                  setShortTermBalance(Number(cleanedValue));
                  handleInputChange('shortTermBucketInput')(Number(cleanedValue));
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Total:</Typography>
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>${totalBalance.toLocaleString()}</Typography>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )}
    {visibleAccordions.investmentStrategy && (
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Long-term bucket
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3} direction="column" width="100%">
            <Box id="Cornerstone">
              <TextField
                label="Cornerstone Conservative"
                value={concervativePercentage}
                onChange={e => {
                  if (concervativePercentage == 0)
                    e.target.value = Number(concervativePercentage) + Number(e.target.value);
                  const value = Number(e.target.value);
                  if (validateInput(value)) {
                    setConcervativePercentage(value); // Update local state
                    handleInputChange('conservativeInput')(value); // Update App.js state
                  }
                }}
                variant="standard"
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                type='tel'
              // sx={{
              //   "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              //                            display: "none",
              //                          },
              //  "& input[type=number]": {
              //                            MozAppearance: "textfield",
              //                          },
              //  }}
              />
              <TextField
                label="Cornerstone Balanced"
                value={balancedPercentage}
                onChange={e => {
                  if (balancedPercentage == 0)
                    e.target.value = Number(balancedPercentage) + Number(e.target.value);
                  const value = Number(e.target.value);
                  if (validateInput(value)) {
                    setBalancedPercentage(value);
                    handleInputChange('balancedInput')(value);
                  }
                }}
                variant="standard"
                fullWidth
                margin="normal"
                placeholder="0"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                type='tel'
              // sx={{
              //   "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              //                            display: "none",
              //                          },
              //  "& input[type=number]": {
              //                            MozAppearance: "textfield",
              //                          },
              //  }}
              />
              <TextField
                label="Cornerstone Balanced Growth"
                value={balancedGrowthPercentage}
                onChange={e => {
                  if (balancedGrowthPercentage == 0)
                    e.target.value = Number(balancedGrowthPercentage) + Number(e.target.value);
                  const value = Number(e.target.value);
                  if (validateInput(value)) {
                    setBalancedGrowthPercentage(value);
                    handleInputChange('balancedGrowthInput')(value);
                  }
                }}
                variant="standard"
                fullWidth
                margin="normal"
                placeholder="0"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                type='tel'
              // sx={{
              //   "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              //                            display: "none",
              //                          },
              //  "& input[type=number]": {
              //                            MozAppearance: "textfield",
              //                          },
              //  }}
              />
              <TextField
                label="Cornerstone Growth"
                value={growthPercentage}
                onChange={e => {
                  if (growthPercentage == 0)
                    e.target.value = Number(growthPercentage) + Number(e.target.value);
                  const value = Number(e.target.value);
                  if (validateInput(value)) {
                    setGrowthPercentage(value);
                    handleInputChange('growthInput')(value);
                  }
                }}
                variant="standard"
                fullWidth
                margin="normal"
                placeholder="0"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                type='tel'
              // sx={{
              //   "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              //                            display: "none",
              //                          },
              //  "& input[type=number]": {
              //                            MozAppearance: "textfield",
              //                          },
              //  }}
              />
              <TextField
                label="Cornerstone High Growth"
                value={highGrowthPercentage}
                onChange={e => {
                  if (highGrowthPercentage == 0)
                    e.target.value = Number(highGrowthPercentage) + Number(e.target.value);
                  const value = Number(e.target.value);
                  if (validateInput(value)) {
                    setHighGrowthPercentage(value);
                    handleInputChange('highGrowthInput')(value);
                  }
                }}
                variant="standard"
                fullWidth
                margin="normal"
                placeholder="0"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                type='tel'
              // sx={{
              //   "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              //                            display: "none",
              //                          },
              //  "& input[type=number]": {
              //                            MozAppearance: "textfield",
              //                          },
              //  }}
              />
              {tabValue === 0 && (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant='subtitle2' sx={{ pt: 0, pb: 0, mt: 2, mb: 1, fontWeight: 'bold', }}>Other funds
                      <Tooltip title="If client is not invested in Cornerstone funds, please estimate current growth allocation of their other funds exposure.">
                        <HelpIcon sx={{ fontSize: '1rem', ml: 1, color: "grey" }} /></Tooltip> </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <TextField
                      label="Allocation"
                      variant='standard'
                      placeholder='0'
                      value={otherFundAllocation}
                      onChange={e => {
                        if (otherFundAllocation == 0)
                          e.target.value = Number(otherFundAllocation) + Number(e.target.value);
                        const value = Number(e.target.value);
                        if (validateInput(value)) {
                          setOtherFundAllocation(value);
                          handleInputChange('otherFundAllocationInput')(value);

                          if (value == 0) // if Other Fund allocation is changed to 0, reset the switchToCornerstone value
                          {
                            setSwitchToCornerstone(0);
                            handleInputChange('switchToCornerstoneInput')(0);
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      type='tel'
                      sx={{ mr: 2 }} />
                    <TextField
                      label="Growth exposure"
                      variant='standard'
                      placeholder='0'
                      value={otherFundGrowthExposure}
                      onChange={e => {
                        if (otherFundGrowthExposure == 0)
                          e.target.value = Number(otherFundGrowthExposure) + Number(e.target.value);
                        const value = Number(e.target.value);
                        if (validateInput(value)) {
                          setOtherFundGrowthExposure(value);
                          handleInputChange('otherFundGrowthExposureInput')(value);
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      type='tel'
                      sx={{ mb: 1 }}
                    />
                  </Box>
                  {/* <Box display="flex" justifyContent="space-between" width="100%">
                  <FormControlLabel control={<Checkbox 
                    checked={switchToCornerstone}
                    onChange={handleCheckboxChange} />} 
                  label={
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant='subtitle2' sx={{lineHeight:1}}>Switch to Cornerstone pre-retirement</Typography>
                    <Tooltip title="Please indicate if client is willing to switch their 'other funds' allocation into Cornerstone funds pre-retirement.">
                      <HelpIcon sx={{
                        ml: 1,
                        color: "grey",
                        fontSize: '1rem',
                        width: '1rem',
                        height: '1rem',
                        '& svg': {
                          fontSize: '1rem',
                        }
                      }}/>
                    </Tooltip>
                    </Box>} 
                  sx={{'& svg': {fontSize: '1.5rem'}, mb:2}} />
                </Box> */}
                  
                <Box display="flex" justifyContent="space-between" width="100%">
                  <RadioGroup
                    value={switchToCornerstone}
                    onChange={handleRadioButtonChange}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={0}
                      disabled={otherFundAllocation === 0}
                      label={<Typography variant='subtitle2' sx={{ lineHeight: 1, fontSize: '0.82rem', color: otherFundAllocation === 0 ? '#CCC' : '#000' }}>Do not switch to Cornerstone</Typography>}
                      sx={{ '& svg': { fontSize: '1.2rem' } }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={1}
                      disabled={otherFundAllocation === 0}
                      label={<Typography variant='subtitle2' sx={{ lineHeight: 1, fontSize: '0.82rem', color: otherFundAllocation === 0 ? '#CCC' : '#000' }}>Glide to Cornerstone</Typography>}
                      sx={{ '& svg': { fontSize: '1.2rem' } }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={2}
                      disabled={otherFundAllocation === 0}
                      label={<Typography variant='subtitle2' sx={{ lineHeight: 1, fontSize: '0.82rem', color: otherFundAllocation === 0 ? '#CCC' : '#000' }}>Switch immediately to Cornerstone</Typography>}
                      sx={{ '& svg': { fontSize: '1.2rem' } }}
                    />
                  </RadioGroup>
                </Box>           

                </>
              )}
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: totalInvestmentStrategy !== 100 ? red[500] : 'inherit' }}>Total</Typography>
                <Typography variant='body1' sx={{ fontWeight: 'bold', color: totalInvestmentStrategy !== 100 ? red[500] : 'inherit' }}>{totalInvestmentStrategy}%</Typography>
              </Box>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )}
    {visibleAccordions.retirementInputs && (
      <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Retirement inputs
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3} direction="column" width="100%">
            {!hideYears && (
              <FormControl fullWidth variant='standard'>
                <InputLabel id="years-to-retirement-label">Years to retirement</InputLabel>
                <Select
                  labelId="years-to-retirement-label"
                  id="years-to-retirement-select"
                  value={yearsToRetirement}
                  label="Years to retirement"
                  onChange={e => {
                    const value = Number(e.target.value);
                    if (!isNaN(value) && value >= 1) { // Validation to ensure only positive numbers
                      setYearsToRetirement(value); // Update local state
                      handleInputChange('yearsToRetirementInput')(value); // Update App.js state
                    }
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth variant='standard'>
              <InputLabel style={!isWindowLarge ? { whiteSpace: 'pre-wrap', marginTop: -18 } : {}} id="target-retirement-growth-exposure-label">Target Retirement Growth Exposure</InputLabel>
              <Select
                labelId="target-retirement-growth-exposure-label"
                id="target-retirement-growth-exposure-label"
                value={targetRetirementGrowthExposure}
                label="Target Retirement Growth Exposure"
                onChange={handleTargetRetirementGrowthExposure}
              >
                <MenuItem value={40}>40%</MenuItem>
                <MenuItem value={45}>45%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={55}>55%</MenuItem>
                <MenuItem value={60}>60%</MenuItem>
                <MenuItem value={65}>65%</MenuItem>
                <MenuItem value={70}>70%</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant='standard'>
              <InputLabel style={!isWindowLarge ? { whiteSpace: 'pre-wrap', marginTop: -18 } : {}} id="annual-drawdown-retirement-rate-label">Annual Drawdown Rate at Retirement</InputLabel>
              <Select
                labelId="annual-drawdown-retirement-rate-label"
                id="annual-drawdown-retirement-rate-label"
                value={annualDrawdownRetirementRate}
                label="Annual Drawdown Rate at Retirement"
                onChange={handleAnnualDrawdownRetirementRate}
              >
                <MenuItem value={4}>4%</MenuItem>
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={6}>6%</MenuItem>
                <MenuItem value={7}>7%</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )}

  </Box>
  );
};
export default ClientInputs;
