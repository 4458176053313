import { LineChart } from '@mui/x-charts/LineChart';

function PersonalisedGlidepathAreaChart({ longTermData, mediumTermData, shortTermData, timeToRetirement, skipAnimation }) {
    return (
        <LineChart
            skipAnimation={skipAnimation}
            slotProps={{
                legend: {
                    position: { vertical: 'bottom', horizontal: 'right' },
                    padding: -5,
                    labelStyle: { fontSize: 10 }
                }
            }}
            height={275}
            series={[
                {
                    data: longTermData,
                    type: 'line',
                    label: 'Long-term bucket',
                    area: true,
                    stack: 'total',
                    showMark: false,
                    color: '#394F87'
                },
                {
                    data: mediumTermData,
                    type: 'line',
                    label: 'Medium-term bucket',
                    area: true,
                    stack: 'total',
                    showMark: false,
                    color: '#2196F3'
                },
                {
                    data: shortTermData,
                    type: 'line',
                    label: 'Short-term bucket',
                    area: true,
                    stack: 'total',
                    showMark: false,
                    color: '#A9A8A8'
                },
            ]}
            xAxis={[
                {
                    scaleType: 'point',
                    data: timeToRetirement,

                },
            ]}
            margin={{top:15}}
        />
    );
}

export default PersonalisedGlidepathAreaChart;